import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import Error404 from './components/common/Error404';
import ErrorPage from './components/common/ErrorPage';
import LoaderComponent from './components/common/LoaderComponent';
import MaintenancePage from './components/common/MaintenancePage';
import AppLayout from './components/layouts/AppLayout';
import AuthLayout from './components/layouts/AuthLayout';
import FormLayout from './components/layouts/FormLayout';
import ScreenLayout from './components/layouts/ScreenLayout';
import history from './historyData';
import EmailLogin from './modules/auth/EmailLogin';
import ForgotPassword from './modules/auth/ForgotPassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import Onboarding from './modules/auth/Onboarding';
import RefreshToken from './modules/auth/RefreshToken';
import Register from './modules/auth/Register';
import RegisterEmail from './modules/auth/RegisterEmail';
import ResetPassword from './modules/auth/ResetPassword';
import VerifyEmail from './modules/auth/VerifyEmail';
import { GET_CURRENT_USER } from './modules/auth/graphql/queries';
import FormFlowWrapper from './modules/form/FormFlowWrapper';
import CreateFormBySlug from './modules/form/editor/CreateFormBySlug';
import FormTemplates from './modules/home/FormTemplates';
import Home from './modules/home/Home';
import PromptScreen from './modules/home/PromptScreen';
import Profile from './modules/profile';
import PlanBilling from './modules/profile/PlanBilling';
import { default as ProfileWrapper } from './modules/profile/ProfileWrapper';
import { AppContextType } from './types/appContext.type';

type FallbackProps = {
  error: Error | null;
  componentStack: string | null;
};

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }: FallbackProps) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES.LOGIN,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [
        {
          path: ROUTES.LOGIN,
          element: (
            <AuthLayout>
              <Login />
            </AuthLayout>
          ),
        },
      ],
    },
    {
      path: ROUTES.ONBOARDING,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.ONBOARDING,
          element: (
            <AuthLayout>
              <Onboarding />
            </AuthLayout>
          ),
        },
      ],
    },
    {
      path: ROUTES.LOGIN_EMAIL,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [
        {
          path: ROUTES.LOGIN_EMAIL,
          element: (
            <AuthLayout>
              <EmailLogin />
            </AuthLayout>
          ),
        },
      ],
    },

    {
      path: ROUTES.REGISTER,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [
        {
          path: ROUTES.REGISTER,
          element: (
            <AuthLayout>
              <Register />
            </AuthLayout>
          ),
        },
      ],
    },
    {
      path: ROUTES.REGISTER_EMAIL,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [
        {
          path: ROUTES.REGISTER_EMAIL,
          element: (
            <AuthLayout>
              <RegisterEmail />
            </AuthLayout>
          ),
        },
      ],
    },
    {
      path: ROUTES.VERIFY_EMAIL,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [
        {
          path: ROUTES.VERIFY_EMAIL,
          element: (
            <AuthLayout>
              <VerifyEmail />
            </AuthLayout>
          ),
        },
      ],
    },
    {
      path: ROUTES.FORGET_PASSWORD,
      element: <PublicRoute />,
      children: [
        {
          path: ROUTES.FORGET_PASSWORD,
          element: (
            <AuthLayout>
              <ForgotPassword />
            </AuthLayout>
          ),
        },
      ],
    },
    // commenting for future changes
    // {
    //   path: ROUTES.SIGNUP,
    //   element: <PublicRoute />,
    //   children: [
    //     {
    //       path: ROUTES.SIGNUP,
    //       element: (
    //         <AuthLayout>
    //           <Register />
    //         </AuthLayout>
    //       ),
    //     },
    //   ],
    // },
    {
      path: ROUTES.RESET,
      element: <PublicRoute />,
      children: [
        {
          path: ROUTES.RESET,
          element: (
            <AuthLayout>
              <ResetPassword />
            </AuthLayout>
          ),
        },
      ],
    },
    {
      path: ROUTES.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES.LOGOUT, element: <Logout /> }],
    },
    {
      path: ROUTES.AUTHENTICATION,
      element: <PrivateRoute />,
      children: [{ path: ROUTES.AUTHENTICATION, element: <RefreshToken /> }],
    },
  ];

  const PROFILE_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <AppLayout />,
          children: [
            {
              path: ROUTES.ACCOUNT,
              element: <ProfileWrapper />,
              children: [
                {
                  index: true,
                  element: <Profile />,
                },
                {
                  path: ROUTES.PLAN_BILLING,
                  element: <PlanBilling />,
                },
              ],
            },
            // {
            //   path: ROUTES.ACCOUNTS,
            //   element: <Accounts />,
            // },
            // {
            //   path: ROUTES.PLAN_BILLING,
            //   element: <PlanBilling />,
            // },
          ],
        },
      ],
    },
  ];

  const DASHBOARD_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <AppLayout />,
          children: [{ path: ROUTES.MAIN, element: <Home /> }],
        },
        {
          path: ROUTES.MAIN,
          element: <AppLayout middle />,
          children: [
            { path: ROUTES.FORM_TEMPLATES, element: <FormTemplates /> },
          ],
        },
        {
          path: ROUTES.MAIN,
          element: <AppLayout middle />,
          children: [{ path: ROUTES.FORM_CREATE, element: <PromptScreen /> }],
        },
      ],
    },
  ];

  const FORM_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <FormLayout />,
          children: [
            {
              path: ROUTES.EDITOR,
              element: <FormFlowWrapper />,
            },
            {
              path: ROUTES.SHARE,
              element: <FormFlowWrapper />,
            },
            {
              path: ROUTES.EDITOR,
              element: <FormFlowWrapper />,
            },
            {
              path: ROUTES.EDITOR_AI_WORKFLOW,
              element: <FormFlowWrapper />,
            },
            {
              path: ROUTES.EDITOR_KNOWLEDGE,
              element: <FormFlowWrapper />,
            },
            {
              path: ROUTES.EDITOR_PREVIEW,
              element: <FormFlowWrapper />,
            },
            {
              path: ROUTES.EDITOR_PUBLISH,
              element: <FormFlowWrapper />,
            },
            {
              path: ROUTES.SHARE,
              element: <FormFlowWrapper />,
            },
            {
              path: `${ROUTES.RESULTS}`,
              element: <FormFlowWrapper />,
            },
            {
              path: `${ROUTES.RESULTS}/:id`,
              element: <FormFlowWrapper />,
            },
            {
              path: `${ROUTES.CREATE_FORM_SLUG}/:slug`,
              element: <CreateFormBySlug />,
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <ScreenLayout />,
          children: [
            {
              path: ROUTES.ERROR,
              element: <ErrorPage />,
            },
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULES,
    ...DASHBOARD_MODULES,
    ...FORM_MODULES,
    ...PROFILE_MODULES,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const {
    initializeAuth,
    getToken,
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;
  const [loading, setLoading] = useState(true);
  const path = history?.location?.pathname;
  const idToken = getToken();

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(idToken, res?.currentUser ?? null);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      // history?.push(ROUTES.LOGOUT);
    },
  });

  useEffect(() => {
    if (idToken && isEmpty(currentUser) && path !== ROUTES?.AUTHENTICATION) {
      getCurrentUser();
    } else {
      setLoading(false);
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
