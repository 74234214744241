import { useMutation } from '@apollo/client';
import { Microphone } from '@phosphor-icons/react';
import { Col, ConfigProvider, Divider, Flex, Form, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import {
  CreateFormInput,
  CreateFormMutation,
} from '../../__generated__/graphql';
import { ROUTES } from '../../common/constants';
import { formValidation } from '../../common/utils';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { CREATE_FORM } from '../form/graphql/mutations';

export default function PromptScreen() {
  const { navigate } = useRouter();
  const [form] = Form.useForm();

  const [createForm, { loading }] = useMutation(CREATE_FORM, {
    onCompleted: (res: CreateFormMutation) => {
      navigate(`${ROUTES.EDITOR}?id=${res.createForm?.data?.id}`);
    },
  });

  const ExampleCard = ({ description }: { description: string }) => {
    return (
      <Col lg={8}>
        <div className="card-box">
          <Paragraph className="card-heading">{'// EXAMPLE'}</Paragraph>
          <Divider className="mt-8 mb-8" />
          <Paragraph className="text-content-secondary" ellipsis={{ rows: 5 }}>
            {description}
          </Paragraph>
        </div>
      </Col>
    );
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    // Check if the Enter key is pressed and no modifier keys are held down
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey
    ) {
      event.preventDefault();
      try {
        await form.validateFields();
        form.submit();
      } catch (e) {
        return e;
      }
    }
  };

  const handleSubmit = async (formValues: CreateFormInput) => {
    await createForm({
      variables: {
        data: {
          prompt: formValues.prompt,
        },
      },
      onError: (error) => {
        form.setFields([
          {
            name: 'prompt',
            errors: [error?.message],
          },
        ]);
      },
    });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBorder: 'var(--border-primary)',
            borderRadius: 16,
            lineWidth: 1,
            fontSize: 16,
            colorText: 'var(--content-primary)',
            colorBgContainer: 'var(--surface-primary)',
          },
        },
      }}
    >
      <div className="onboarding-wrapper">
        <Flex vertical gap={8}>
          <Title className="font-secondary text-center mb-8 semi-bold">
            Create with AI
          </Title>
          <Flex vertical>
            <Paragraph className="text-description text-center text-content-secondary">
              Just type your request clearly. Be specific for best results.
            </Paragraph>
            <Paragraph className="text-description text-center text-content-secondary">
              Let AI handle the rest!
            </Paragraph>
          </Flex>
        </Flex>
        <section className="container">
          <Form form={form} className="prompt-form" onFinish={handleSubmit}>
            <div className="information-wrapper">
              <div className="relative">
                <Form.Item name="prompt" rules={[formValidation.required]}>
                  <TextArea
                    autoSize={{ minRows: 3 }}
                    placeholder="What information do you want to collect?"
                    className="input-prompt"
                    onKeyDown={handleKeyDown}
                    maxLength={500}
                  />
                </Form.Item>
                <div className="float-microphone">
                  <Microphone
                    size={20}
                    weight="fill"
                    color="var(--content-tertiary)"
                  />
                </div>
              </div>
              <Flex justify="center">
                <CommonButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  size="large"
                >
                  Submit
                </CommonButton>
              </Flex>
            </div>
          </Form>
        </section>
        <section className="container">
          <Row className="example-list" gutter={[16, 16]}>
            <ExampleCard description="Feedback form for my party with up to 5 questions and a photo upload option." />
            <ExampleCard description="Check-in form for your health and wellness program." />
            <ExampleCard description="Survey to measure customer satisfaction with our services." />
          </Row>
        </section>
      </div>
    </ConfigProvider>
  );
}
