import { useMutation, useQuery } from '@apollo/client';
import { Check, X } from '@phosphor-icons/react';
import { Flex, Skeleton } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { lowerCase, map, startCase } from 'lodash';
import { useState } from 'react';
import {
  SubscriptionPlan,
  SubscriptionPlanType,
} from '../../__generated__/graphql';
import {
  currencyCodeToSymbol,
  handleGraphQlError,
  handleGraphQlSuccess,
} from '../../common/utils';
import PremiumLogo from '../../components/common/PremiumLogo';
import UpgradeSuccessful from '../../components/common/UpgradeSuccessful';
import CommonButton from '../../components/primitives/CommonButton';
import { ACTIVATE_SUBSCRIPTION_PLAN } from './graphql/mutations';
import { GET_FEATURE_LIST, GET_PLAN_LIST } from './graphql/queries';
import { FeatureCategory, PlanFeature, PlanType } from './profile.types';

export default function PlanBilling() {
  const [upgradeModal, setUpgradeModal] = useState(false);
  const FeatureListItem = ({ list }: { list: string[] }) => {
    return (
      <ul className="mt-16">
        {map(list, (text) => {
          return (
            <li>
              <Check color="var(--system-green-dark)" size={20} weight="bold" />
              {text}
            </li>
          );
        })}
        <li>& More...</li>
      </ul>
    );
  };

  const PlanList = () => {
    const { loading, refetch, data } = useQuery(GET_PLAN_LIST);
    const [activateSubscriptionPlan, { loading: activateLoading }] =
      useMutation(ACTIVATE_SUBSCRIPTION_PLAN, {
        onError() {},
      });

    const handleActivatePlan = (id: string) => {
      activateSubscriptionPlan({
        variables: {
          data: {
            subscriptionPlanId: id,
          },
        },
        onCompleted: (res) => {
          handleGraphQlSuccess(res.activateSubscriptionPlan?.message);
          refetch();
          setUpgradeModal(true);
        },
      });
    };

    if (loading) {
      return <Skeleton active />;
    }
    return (
      <div className="subscribe-modal zinq-modal">
        <div className="zinq-modal-content">
          <div className="subscribe-modal-wrapper">
            <div className="subscribe-plan-card-wrapper">
              {map(
                data?.subscriptionPlanKeyFeatures?.data,
                (item: SubscriptionPlan) => {
                  return (
                    <div className="subscribe-plan-card">
                      {lowerCase(item.label!) !== PlanType.FREE ? (
                        <div className="tag premium mb-8">
                          PREMIUM <PremiumLogo />
                        </div>
                      ) : (
                        <div className="tag mb-8">{item.label}</div>
                      )}

                      <Title level={2}>
                        {`${currencyCodeToSymbol(item.currency || '')}${item.price || 0}`}
                        {lowerCase(item.label!) !== PlanType.FREE &&
                        item.type == SubscriptionPlanType.Monthly
                          ? '/month'
                          : ''}
                      </Title>
                      <p className="text-sm mt-16">{item.description}</p>
                      {item.keyFeatures && (
                        <FeatureListItem list={item.keyFeatures as string[]} />
                      )}
                      {lowerCase(item.title!) !== PlanType.FREE ? (
                        <div className="plan-button-account-page">
                          {item.isSubscribed ? (
                            <CommonButton type="default">
                              Current Plan
                            </CommonButton>
                          ) : (
                            <CommonButton
                              type="primary"
                              onClick={() => handleActivatePlan(item.id!)}
                              loading={activateLoading}
                            >
                              Upgrade Now For Free
                            </CommonButton>
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                },
              )}
            </div>
            <div className="text-center text-sm mt-24 subscribe-modal-footer-text">
              *Premium is available for FREE while we are in beta.
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FeatureList = () => {
    const { loading, refetch, data } = useQuery(GET_FEATURE_LIST);
    const [activateSubscriptionPlan, { loading: activateLoading }] =
      useMutation(ACTIVATE_SUBSCRIPTION_PLAN, {
        onError() {},
      });

    const handleActivatePlan = (id: string) => {
      activateSubscriptionPlan({
        variables: {
          data: {
            subscriptionPlanId: id,
          },
        },
        onCompleted: (res) => {
          handleGraphQlSuccess(res.activateSubscriptionPlan?.message);
          refetch();
          setUpgradeModal(true);
        },
        onError: (err) => {
          handleGraphQlError(err.message);
          refetch();
        },
      });
    };

    const renderFeatureItem = (plan: SubscriptionPlan, key: string) => {
      const featureObj = plan?.features?.find(
        (val: PlanFeature) => val.key === key,
      )?.value;

      if (featureObj === undefined) return null;

      switch (typeof featureObj) {
        case 'number':
          return (
            <Paragraph className="mb-0">
              {featureObj === -1 ? 'Unlimited' : featureObj.toString()}
            </Paragraph>
          );
        case 'string':
          return <Paragraph className="mb-0">{featureObj}</Paragraph>;
        case 'boolean':
          return featureObj ? (
            <Check color="var(--system-green-dark)" size={20} weight="bold" />
          ) : (
            <X color="var(--system-red-dark)" size={20} weight="bold" />
          );
        default:
          return <Paragraph className="mb-0">{String(featureObj)}</Paragraph>;
      }
    };

    if (loading) {
      return <Skeleton active />;
    }
    const freePlan = data?.subscriptionPlans?.data?.filter(
      (item) => lowerCase(item?.label || '') === PlanType.FREE,
    )?.[0];
    const premiumPlan = data?.subscriptionPlans?.data?.filter(
      (item) => lowerCase(item?.label || '') === PlanType.PREMIUM,
    )?.[0];
    return (
      <div className="feature-wrapper">
        <Title level={2} className="text-center">
          Feature Details
        </Title>
        <div className="feature-table mt-32">
          <table>
            <thead>
              <tr>
                <th>Feature</th>
                <th>{startCase(lowerCase(freePlan?.label || ''))}</th>
                <th>
                  <Flex gap={8} align="center" justify="center">
                    {startCase(lowerCase(premiumPlan?.label || ''))}
                    <PremiumLogo />
                  </Flex>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan={3}>Form Creation</th>
              </tr>
              {map(
                freePlan?.features.filter(
                  (item: PlanFeature) =>
                    item.categoryKey === FeatureCategory.FORM_CREATION,
                ),
                (item) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{renderFeatureItem(freePlan!, item.key)}</td>
                    <td>{renderFeatureItem(premiumPlan!, item.key)}</td>
                  </tr>
                ),
              )}
              <tr>
                <th colSpan={3}>Form Experience</th>
              </tr>
              {map(
                freePlan?.features.filter(
                  (item: PlanFeature) =>
                    item.categoryKey === FeatureCategory.FORM_EXPERIENCE,
                ),
                (item) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{renderFeatureItem(freePlan!, item.key)}</td>
                    <td>{renderFeatureItem(premiumPlan!, item.key)}</td>
                  </tr>
                ),
              )}

              <tr>
                <th colSpan={3}>Sharing</th>
              </tr>
              {map(
                freePlan?.features.filter(
                  (item: PlanFeature) =>
                    item.categoryKey === FeatureCategory.SHARING,
                ),
                (item) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{renderFeatureItem(freePlan!, item.key)}</td>
                    <td>{renderFeatureItem(premiumPlan!, item.key)}</td>
                  </tr>
                ),
              )}

              <tr>
                <th colSpan={3}>Results</th>
              </tr>
              {map(
                freePlan?.features.filter(
                  (item: PlanFeature) =>
                    item.categoryKey === FeatureCategory.RESULTS,
                ),
                (item) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{renderFeatureItem(freePlan!, item.key)}</td>
                    <td>{renderFeatureItem(premiumPlan!, item.key)}</td>
                  </tr>
                ),
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}></td>
                <td className="text-center">
                  {premiumPlan?.isSubscribed ? (
                    <CommonButton type="default">Current Plan</CommonButton>
                  ) : (
                    <CommonButton
                      type="primary"
                      onClick={() => handleActivatePlan(premiumPlan?.id || '')}
                      loading={activateLoading}
                    >
                      Upgrade Now For Free
                    </CommonButton>
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="plan-and-billing">
      <PlanList />
      <FeatureList />
      <UpgradeSuccessful
        isVisible={upgradeModal}
        onClose={() => {
          setUpgradeModal(false);
        }}
      />
    </div>
  );
}
