import { Alert, Flex, Form, Input, Modal } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { formValidation } from '../../common/utils';
import CommonButton from '../primitives/CommonButton';

type CommonKeyModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: (value: string) => void;
  title: string;
  description: string;
  initialValue?: string;
};

const CommonKeyModal: React.FC<CommonKeyModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
  title,
  description,
  initialValue = '',
}) => {
  const [form] = Form.useForm();

  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm(values.exportKey);
        form.resetFields();
      })
      .catch(() => {
        // do nothing
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const ModalTitle = () => (
    <Title level={4} className="font-secondary text-content-primary">
      {title}
    </Title>
  );

  return (
    <Modal
      title={<ModalTitle />}
      open={isVisible}
      onCancel={handleCancel}
      className="common-key-modal"
      footer={[
        <CommonButton key="cancel" onClick={handleCancel}>
          Cancel
        </CommonButton>,
        <CommonButton key="confirm" type="primary" onClick={handleConfirm}>
          Confirm
        </CommonButton>,
      ]}
    >
      <Flex vertical align="flex-start" gap={16}>
        <Paragraph className="text-sm text-content-secondary mb-0">
          {description}
        </Paragraph>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ exportKey: initialValue }}
          className="w-full"
        >
          <Form.Item name="exportKey" rules={[formValidation.required]}>
            <Input placeholder="Export key" className="w-full" />
          </Form.Item>
        </Form>
        <Flex vertical align="flex-start" gap={4} className="w-full">
          <Alert
            type="warning"
            message="Note: It will changed for previous submissions as well."
            className="w-full text-sm"
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CommonKeyModal;
