import { Circle, Desktop, DeviceMobileCamera } from '@phosphor-icons/react';
import { Flex, Segmented } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';
import { cloneDeep, lowerCase } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  FormSubmissionType,
  FormThemeMode,
  FormThemeVariation,
} from '../../../__generated__/graphql';
import { BrowserStatusBarSvg, MobileIndicatorSvg } from '../../../assets/icons';
import {
  DEFAULT_TIME_FORMAT,
  getThemeBackground,
} from '../../../common/constants';
import { getFormTypeOptions } from '../../../common/utils';
import { AppActionType, AppContextType } from '../../../types/appContext.type';
import MadeWithZinq from '../../branding/MadeWithZinq';
import {
  FormFieldType,
  FormPreviewType,
  FormSchemaType,
  FormThemeType,
  PageLayoutType,
  SubscriptionPlans,
} from '../form.types';
import CallPreview from '../view/CallPreview';
import ClassicUIPreview from '../view/ClassicUIPreview';
import ConversationalUIPreview from '../view/ConversationalUIPreview';
import AIProcessingForm from './utils/AIProcessingForm';
import FormLoading from './utils/FormLoading';

export default function FormPreviewMode({ showTabs }: { showTabs: boolean }) {
  const [currentItem, setCurrentItem] = useState<FormSchemaType>();
  const [formType, setFormType] = useState<FormSubmissionType>();
  const [previewType, setPreviewType] = useState<FormPreviewType>(
    FormPreviewType.desktop,
  );
  const {
    state: {
      appState,
      activeThemeVariationId,
      themeList,
      activeThemeId,
      systemThemeMode,
      activeMode,
      formItems,
      formSettings,
    },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const [selectedTheme, setSelectedTheme] = useState<FormThemeVariation>();
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  useEffect(() => {
    if (formItems?.items && formItems?.activeId) {
      if (formItems.items?.length > 0) {
        const itemObj = formItems?.items?.find(
          (item: FormSchemaType) => item.question_id === formItems?.activeId,
        );
        setCurrentItem(cloneDeep(itemObj));
      }
    }
  }, [formItems]);

  useEffect(() => {
    if (activeThemeVariationId && themeList?.length > 0) {
      setSelectedTheme(
        themeList
          ?.filter((val) => val.id === activeThemeId)?.[0]
          ?.themeVariations?.filter(
            (val) => val?.id === activeThemeVariationId,
          )?.[0] || {},
      );
    }
  }, [activeThemeVariationId, themeList]);

  const mode = activeMode === FormThemeMode.Auto ? systemThemeMode : activeMode;

  const renderFormPreview = () => {
    switch (true) {
      case appState?.formCreateError:
        return (
          <Paragraph className="mb-0 text-content-tertiary semi-bold text-m">
            Add a question to see the preview of it here.
          </Paragraph>
        );
      case appState?.formFetchLoading:
        return <FormLoading />;
      case appState?.formCreateLoading:
        return <AIProcessingForm type="PREVIEW" />;
      case formItems.items?.length === 0:
        return (
          <Paragraph className="mb-0 text-content-tertiary semi-bold text-m">
            Add a question to see the preview of it here.
          </Paragraph>
        );
      default:
        return (
          <>
            {formSettings?.previewType === FormSubmissionType.Classic && (
              <div className="classic-form-preview">
                {formItems?.items && (
                  <ClassicUIPreview itemList={formItems?.items} />
                )}
              </div>
            )}
            {formSettings?.previewType === FormSubmissionType.Conversation &&
              currentItem && (
                <div
                  className={`conversational-form-preview scrollbar-none ${previewType === FormPreviewType.mobile ? 'mobile' : ''}`}
                >
                  {selectedTheme && (
                    <ConversationalUIPreview
                      item={currentItem}
                      isMobile={previewType === FormPreviewType.mobile}
                      isUpload={false}
                      autoFocus={false}
                      themeMode={mode as FormThemeMode}
                      onFinish={() => {
                        // do nothing for preview
                      }}
                      activeTheme={
                        selectedTheme?.colorPalette?.[lowerCase(mode as string)]
                      }
                      handleCTAClick={() => {
                        // do nothing for preview
                      }}
                      showFooterButtons={true}
                    />
                  )}
                  {previewType === FormPreviewType.mobile && (
                    <span className="floating-bottom-bar"></span>
                  )}
                </div>
              )}
            {formSettings?.previewType === FormSubmissionType.Voice && (
              <div className="conversational-form-preview">
                <CallPreview
                  activeTheme={
                    selectedTheme?.colorPalette?.[lowerCase(mode as string)]
                  }
                />
              </div>
            )}
          </>
        );
    }
  };

  const hasAlphaFeature =
    currentUser.subscriptionPlan?.features?.find(
      ({ key }: { key: string }) => key === SubscriptionPlans.ALPHA_FEATURE,
    )?.value === true;

  const isSpecialPage =
    currentItem?.answer_type === FormFieldType.WELCOME_PAGE ||
    currentItem?.answer_type === FormFieldType.END_PAGE;

  const hasBackgroundImage =
    currentItem?.page_layout === PageLayoutType.PAGE_FILLED &&
    currentItem?.bg_image_url;

  const themeBackgroundStyle = getThemeBackground(
    selectedTheme?.type as FormThemeType,
    selectedTheme?.colorPalette?.[lowerCase(mode as string)],
  );

  const style = isSpecialPage && hasBackgroundImage ? {} : themeBackgroundStyle;

  const renderHeader = () => {
    switch (previewType) {
      case FormPreviewType.desktop:
        return (
          <Flex className="mock-desktop-header" align="center">
            <Flex align="center" gap={6}>
              <Circle weight="fill" color="#FF5F57" size={16} />
              <Circle weight="fill" color="#FFBD2E" size={16} />
              <Circle weight="fill" color="#28CA42" size={16} />
            </Flex>
            <Flex align="center" gap={6} flex={2} justify="center">
              <BrowserStatusBarSvg />
            </Flex>
          </Flex>
        );
      case FormPreviewType.mobile:
        return (
          <Flex
            className="mock-mobile-header"
            align="center"
            gap={6}
            justify="space-between"
          >
            <Paragraph className="text-neutrals mb-0 text-m">
              {dayjs().format(DEFAULT_TIME_FORMAT)}
            </Paragraph>
            <MobileIndicatorSvg />
          </Flex>
        );

      default:
        return '';
    }
  };

  return (
    <section>
      {showTabs && (
        <Flex className="mb-16" gap={16} justify="space-between" align="center">
          <Segmented
            className="app-segments w-auto"
            value={formSettings?.previewType}
            options={getFormTypeOptions(hasAlphaFeature)}
            onChange={(value) => {
              dispatch({
                type: AppActionType.setFormSettings,
                data: {
                  previewType: value,
                },
              });
            }}
          />
          <Segmented
            className="app-segments rounded w-auto"
            value={previewType}
            options={[
              {
                value: FormPreviewType.desktop,
                icon: (
                  <Desktop
                    size={18}
                    color="var(--content-tertiary)"
                    weight="fill"
                  />
                ),
              },
              {
                value: FormPreviewType.mobile,
                icon: (
                  <DeviceMobileCamera
                    size={18}
                    color="var(--content-tertiary)"
                    weight="fill"
                  />
                ),
              },
            ]}
            onChange={(value) => {
              setPreviewType(value);
            }}
          />
        </Flex>
      )}
      <Flex
        className={`form-preview-mode-wrapper ${showTabs ? 'tab-height' : ''}`}
        vertical
      >
        {renderHeader()}
        <div
          className={`form-preview-mode scrollbar-none bg-surface-primary ${previewType === FormPreviewType.desktop ? 'desktop' : 'mobile'}`}
          style={style}
        >
          {isSpecialPage && hasBackgroundImage ? (
            <>
              <img
                src={currentItem?.bg_image_url}
                alt="preview-welcome-page"
                className="preview-bg-image"
              />
              <div className="img-overlay" />
            </>
          ) : null}
          {renderFormPreview()}
          {mode && <MadeWithZinq mode={mode} />}
        </div>
      </Flex>
    </section>
  );
}
