import { useMutation, useQuery } from '@apollo/client';
import { CaretLeft, Eye, GearSix, PaperPlaneTilt } from '@phosphor-icons/react';
import { Flex, Input, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  FormStatus,
  FormSubmissionType,
  FormTheme,
} from '../../__generated__/graphql';
import { FORM_ID, ROUTES, TOAST_MESSAGE } from '../../common/constants';
import PublishAlertModal from '../../components/common/PublishAlertModal';
import UnpublishModal from '../../components/common/UnpublishModal';
import { messageContext } from '../../components/context/AppContextHolder';
import CommonButton from '../../components/primitives/CommonButton';
import useQueryParams from '../../hooks/useQueryParams';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { FormSchemaType } from '../form/form.types';
import Results from '../results/Results';
import Editor from './editor/Editor';
import Share from './editor/Share';
import { PUBLISH_FORM, UPDATE_FORM_TITLE } from './graphql/mutations';
import { GET_FORM, GET_FORM_THEMES } from './graphql/queries';

export default function FormFlowWrapper() {
  const {
    navigate,
    location: { pathname },
  } = useRouter();

  const { getQueryParam } = useQueryParams();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const formId = getQueryParam('id') as string;
  const activeTab = getQueryParam('activeTab') as string;
  const [form] = useForm();
  const [alertModalOpen, setAlertModalOpen] = useState<boolean>(false);

  // get form base url
  const FORM_BASE_URL = process.env.REACT_APP_FORM_BASE_URL!;

  const {
    dispatch,
    state: { formSettings },
  } = useContext(AppContext) as AppContextType;

  const onChange = (key: string) => {
    navigate(`${key}/?id=${formId}`);
  };

  const { loading } = useQuery(GET_FORM_THEMES, {
    variables: {
      data: {
        formId,
      },
    },
    onCompleted: (res) => {
      dispatch({
        type: AppActionType.setAppThemes,
        data: res.formThemes?.data as FormTheme[],
      });
    },
  });

  const { loading: formLoading, data } = useQuery(GET_FORM, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        formId: formId as string,
      },
    },
    onCompleted: (res) => {
      const formDesigns = res.form?.data?.designs?.[0];
      const formStructure = res.form?.data?.structures?.[0]?.structure;

      dispatch({
        type: AppActionType.setFormSettings,
        data: {
          ...res.form?.data,
        },
      });
      dispatch({
        type: AppActionType.setActiveThemeIds,
        data: {
          activeThemeId: formDesigns?.themeId,
          activeThemeVariationId: formDesigns?.themeVariationId,
          activeMode: formDesigns?.themeMode,
        },
      });
      dispatch({
        type: AppActionType.setFormItems,
        data: {
          items: formStructure as FormSchemaType[],
          activeId: formStructure?.[0]?.question_id,
        },
      });
    },
    onError: () => {
      navigate(ROUTES.NOT_FOUND);
    },
  });

  const [publishFormMutate, { loading: publishLoading }] = useMutation(
    PUBLISH_FORM,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          formId,
        },
      },
      onCompleted: (res) => {
        dispatch({
          type: AppActionType.setFormSettings,
          data: {
            ...res.publishForm?.data,
          },
        });
        setAlertModalOpen(false);
      },
      onError: () => {},
    },
  );

  const items: TabsProps['items'] = [
    {
      key: ROUTES.EDITOR,
      label: 'Editor',
      children: <Editor />,
    },
    {
      key: ROUTES.SHARE,
      label: 'Share',
      children: <Share />,
    },
    {
      key: ROUTES.RESULTS,
      label: 'Results',
      children: <Results />,
    },
  ];

  const handlePublishBtn = () => {
    if (formSettings?.status === FormStatus.Draft) {
      setAlertModalOpen(true);
    } else {
      publishFormMutate();
    }
  };

  // publish button variables
  const isPublished = formSettings?.status === FormStatus.Published;
  const isUpdated = formSettings?.isUpdated;
  const isDisabled = (isPublished && !isUpdated) || publishLoading;
  const buttonLabel = publishLoading
    ? 'Publishing...'
    : isPublished && isUpdated
      ? 'Republish'
      : 'Publish';

  useEffect(() => {
    if (formId) {
      localStorage.setItem(FORM_ID, formId);
    }
  }, [formId]);

  const LeftContent = () => {
    const [title, setTitle] = useState<string>(formSettings?.title as string);
    const [originalTitle] = useState<string>(formSettings?.title as string);

    const [updateFormMutate, { loading: updateLoading }] = useMutation(
      UPDATE_FORM_TITLE,
      {
        fetchPolicy: 'network-only',
        onError: () => {},
      },
    );

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
    };

    const handleTitleSubmit = () => {
      if (originalTitle !== title) {
        updateFormMutate({
          variables: {
            where: {
              formId,
            },
            data: {
              title: title?.trim() ? title.trim() : 'Untitled',
            },
          },
          onCompleted: (res) => {
            dispatch({
              type: AppActionType.setFormSettings,
              data: {
                title: res.updateForm?.data?.title,
              },
            });
            setIsEdit(false);
          },
        });
      } else {
        setIsEdit(false);
      }
    };

    return (
      <Flex className="form-flow-left" gap={8} align="center">
        <CommonButton
          shape="circle"
          size="small"
          shadow={false}
          icon={<CaretLeft className="pointer" size={20} />}
          onClick={() => navigate(ROUTES.MAIN)}
        ></CommonButton>
        <Input
          value={title}
          className="mb-0 text-m text-content-secondary"
          onChange={handleTitleChange}
          onPressEnter={handleTitleSubmit}
          onBlur={handleTitleSubmit}
          variant="borderless"
          placeholder="Type here..."
        />
      </Flex>
    );
  };

  const RightContent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalClose = () => {
      setIsModalOpen(false);
    };

    const handleFormPreviewBtn = () => {
      let previewType = formSettings?.previewType;
      if (formSettings?.previewKey) {
        if (formSettings?.previewType === FormSubmissionType.Voice) {
          previewType = FormSubmissionType.Conversation; // for default preview
        }
        window.open(
          `${FORM_BASE_URL}${ROUTES.FORM_PREVIEW}/${formSettings?.previewKey}?previewType=${previewType}`,
          '_blank',
        );
      } else {
        messageContext.error(TOAST_MESSAGE.previewNotAvailable);
      }
    };

    return (
      <Flex gap={8}>
        {isPublished && (
          <CommonButton
            type="text"
            shape="circle"
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="shadow-hidden"
            icon={
              <GearSix color="var(--content-primary)" size={18} weight="fill" />
            }
          />
        )}
        <CommonButton
          type="text"
          icon={<Eye color="var(--content-primary)" size={16} weight="fill" />}
          onClick={handleFormPreviewBtn}
        >
          Preview
        </CommonButton>
        <CommonButton
          type="primary"
          iconPosition="end"
          icon={
            <PaperPlaneTilt
              color="var(--content-inverse-primary)"
              size={16}
              weight="fill"
            />
          }
          onClick={handlePublishBtn}
          disabled={isDisabled}
        >
          {buttonLabel}
        </CommonButton>
        {isModalOpen && (
          <UnpublishModal
            open={isModalOpen}
            onOk={handleModalClose}
            onCancel={handleModalClose}
            onSuccess={handleModalClose}
          />
        )}
        <PublishAlertModal
          isVisible={alertModalOpen}
          onCancel={() => setAlertModalOpen(false)}
          onConfirm={() => {
            setAlertModalOpen(false);
            publishFormMutate();
          }}
          onReviewSettingsClick={() => {
            setAlertModalOpen(false);
            navigate(`${ROUTES.EDITOR}/?id=${formId}&activeTab=settings`);
          }}
          title="Almost Ready to Publish!"
          description="Before you publish, check your form settings to ensure everything is just right. Customize your notifications, schedule, and more."
        />
      </Flex>
    );
  };

  return (
    <div className="form-flow-wrapper">
      <section>
        <Tabs
          activeKey={`/${pathname?.split('/')?.[1]}`}
          items={items}
          className="header-tabs app-tabs"
          onChange={onChange}
          centered
          tabBarExtraContent={{
            left: <LeftContent />,
            right: <RightContent />,
          }}
        />
      </section>
    </div>
  );
}
