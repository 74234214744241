import { Col, Flex, Modal, Row } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import CommonButton from '../primitives/CommonButton';

type CardProps = {
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
  cardIcon: React.ReactNode;
  isPremium?: boolean;
};

type TwoCardModalProps = {
  open: boolean;
  onCancel: () => void;
  title: string;
  description: string;
  cards: CardProps[];
};

const Card = ({
  title,
  description,
  buttonText,
  onClick,
  isPremium,
  cardIcon,
}: CardProps) => (
  <Flex
    className={`card-wrapper ${isPremium ? 'border-primary' : ''}`}
    align="flex-start"
    vertical
    gap={24}
  >
    {/* {isPremium && <span className="premium-badge">Premium</span>} */}
    <Flex vertical align="flex-start" gap={8}>
      <Title level={4} className="font-secondary text-content-primary">
        {title}
      </Title>
      <Paragraph className="text-sm text-content-secondary mb-0">
        {description}
      </Paragraph>
    </Flex>
    <CommonButton
      type="default"
      onClick={onClick}
      size="small"
      disabled={isPremium}
    >
      {buttonText}
    </CommonButton>
    <div className="card-icon">{cardIcon}</div>
  </Flex>
);

const FormTypeModal = ({
  open,
  onCancel,
  title,
  description,
  cards,
}: TwoCardModalProps) => {
  const ModalTitle = () => (
    <Title
      level={1}
      className="font-secondary text-content-primary text-center"
    >
      {title}
    </Title>
  );

  return (
    <Modal
      title={<ModalTitle />}
      open={open}
      className="form-selection-modal"
      onCancel={onCancel}
      footer={null}
      width={714}
      onClose={onCancel}
      destroyOnClose
    >
      <Flex vertical align="center" gap={24}>
        <Paragraph className="text-sm text-content-secondary mb-0 text-center">
          {description}
        </Paragraph>
        <Row gutter={[24, 24]}>
          {cards.map((card, index) => (
            <Col span={12} key={index}>
              <Card {...card} />
            </Col>
          ))}
        </Row>
      </Flex>
    </Modal>
  );
};

export default FormTypeModal;
