import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { CopySimple, DotsThreeVertical, Trash } from '@phosphor-icons/react';
import { Dropdown, Flex, MenuProps, Tag } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { lowerCase, startCase } from 'lodash';
import { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  Form,
  FormStatus,
  FormThemeMode,
} from '../../../__generated__/graphql';
import { ROUTES, getThemeBackground } from '../../../common/constants';
import { getThemeClassName, handleGraphQlSuccess } from '../../../common/utils';
import CommonDeleteModal from '../../../components/common/CommonDeleteModal';
import useRouter from '../../../hooks/useRouter';
import { AppContextType } from '../../../types/appContext.type';
import { FormThemeType, Palette } from '../../form/form.types';
import { DELETE_FORM, DUPLICATE_FORM } from '../graphql/mutations';

export default function FeedbackCard({
  status,
  id,
  totalSubmissionsCount,
  notViewedSubmissionsCount,
  designs,
  title,
  onDelete,
  onDuplicate,
}: Form & {
  onDelete: () => void;
  onDuplicate: () => void;
}) {
  const {
    state: { systemThemeMode },
  } = useContext(AppContext) as AppContextType;
  const [open, setOpen] = useState(false);
  const { navigate } = useRouter();

  const items: MenuProps['items'] = [
    {
      label: <Paragraph className="mb-0">Duplicate</Paragraph>,
      key: 'DUPLICATE',
      icon: (
        <CopySimple size={16} color="var(--content-primary)" weight="regular" />
      ),
    },
    {
      label: (
        <Paragraph className="mb-0 text-danger-on-surface">Delete</Paragraph>
      ),
      key: 'DELETE',
      icon: (
        <Trash size={16} color="var(--danger-on-surface)" weight="regular" />
      ),
    },
  ];

  // mutation to delete individual form
  const [deleteFormMutate, { loading }] = useMutation(DELETE_FORM, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  // mutation to delete individual form
  const [duplicateFormMutate, { loading: duplicateLoading }] = useMutation(
    DUPLICATE_FORM,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
    },
  );

  const handleDelete = () => {
    deleteFormMutate({
      variables: {
        where: {
          formId: id as string,
        },
      },
      onCompleted: () => {
        setOpen(false);
        onDelete();
      },
    });
  };

  const handleDuplicate = () => {
    duplicateFormMutate({
      variables: {
        where: {
          formId: id as string,
        },
      },
      onCompleted: (res) => {
        onDuplicate();
        handleGraphQlSuccess(res.duplicateForm?.message);
      },
    });
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'DUPLICATE') {
      handleDuplicate();
    }
    if (e.key === 'DELETE') {
      setOpen(true);
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleRedirect = () => {
    if (id) {
      navigate(`${ROUTES.EDITOR}/?id=${id}`);
    }
  };

  const activeDesign = designs?.[0];

  const mode =
    activeDesign?.themeMode === FormThemeMode.Auto
      ? systemThemeMode
      : activeDesign?.themeMode;

  const activeTheme: Palette =
    activeDesign?.themeVariation?.colorPalette[
      lowerCase(mode as FormThemeMode)
    ];

  return (
    <>
      <div
        className="feedback-card"
        onClick={handleRedirect}
        style={getThemeBackground(
          activeDesign?.themeVariation?.type as FormThemeType,
          activeTheme,
        )}
      >
        <Flex vertical gap={82} justify="space-between" className="h-full">
          <Flex justify="space-between" align="center">
            {status === FormStatus.Draft ? (
              <Tag className="draft-tag text-meta medium">
                {startCase(lowerCase(status as string))}
              </Tag>
            ) : (
              <div />
            )}

            <div onClick={(e) => e.stopPropagation()}>
              {duplicateLoading ? (
                <LoadingOutlined />
              ) : (
                <Dropdown menu={menuProps} trigger={['click']}>
                  <DotsThreeVertical
                    size={18}
                    color={
                      mode === FormThemeMode.Dark
                        ? 'var(--content-inverse-primary)'
                        : 'var(content-primary)'
                    }
                  />
                </Dropdown>
              )}
            </div>
          </Flex>
          <Flex vertical gap={4}>
            <Title
              ellipsis={{ rows: 2 }}
              level={5}
              className={`font-secondary ${getThemeClassName(mode as FormThemeMode, false)}`}
            >
              {title}
            </Title>
            <Flex gap={4} align="center">
              <Paragraph
                className="mb-0 font-secondary"
                style={{
                  color:
                    mode === FormThemeMode.Dark
                      ? 'var(--content-inverse-tertiary)'
                      : 'var(--content-tertiary)',
                }}
              >
                {`${totalSubmissionsCount || 0} Responses`}
              </Paragraph>
              {Number(notViewedSubmissionsCount) > 0 && (
                <Tag
                  className={`new-response-tag text-meta ${getThemeClassName(mode as FormThemeMode, true, true)}`}
                >{`${notViewedSubmissionsCount} New`}</Tag>
              )}
            </Flex>
          </Flex>
        </Flex>
      </div>
      <CommonDeleteModal
        open={open}
        title="Delete form"
        content="Are you sure you want to delete this? All form results and associated information will be permanently removed. This action cannot be undone."
        onClose={() => {
          setOpen(false);
        }}
        onOk={() => {
          handleDelete();
        }}
        confirmLoading={loading}
        cancelButtonProps={{
          disabled: loading,
        }}
      />
    </>
  );
}
