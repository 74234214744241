export enum PlanType {
  FREE = 'free',
  PREMIUM = 'premium',
}

export type PlanFeature = {
  key: string;
  name: string;
  value: number;
  categoryKey: string;
  description: string;
};

export enum FeatureCategory {
  FORM_CREATION = 'FORM_CREATION',
  FORM_EXPERIENCE = 'FORM_EXPERIENCE',
  SHARING = 'SHARING',
  RESULTS = 'RESULTS',
}
