import { useLazyQuery, useMutation } from '@apollo/client';
import { CaretDown } from '@phosphor-icons/react';
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Spin,
  Statistic,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import Link from 'antd/es/typography/Link';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import dayjs, { Dayjs } from 'dayjs';
import {
  User as AuthUser,
  GoogleAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
  User as UserAuth,
  UserCredential,
  signInWithPopup,
} from 'firebase/auth';
import { isEmpty, omit } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  FormThemeMode,
  LoginType,
  User,
  UserProfileData,
} from '../../__generated__/graphql';
import { GoogleIcon, MicrosoftIcon } from '../../assets/icons';
import {
  COMPANY_INFO,
  DROPDOWN_LIST,
  REFRESH_TOKEN,
  ROUTES,
  TOAST_MESSAGE,
  TOKEN,
} from '../../common/constants';
import {
  deleteCookie,
  formValidation,
  getDefaultTimezone,
  handleFirebaseError,
} from '../../common/utils';
import Logo from '../../components/common/Logo';
import { messageContext } from '../../components/context/AppContextHolder';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { FormField } from '../form/editor/fields/FormField';
import { FormFieldType } from '../form/form.types';
import {
  ContactFormType,
  ErrorTypes,
  InputDataType,
  OTPFormType,
  TokenType,
} from './auth.types';
import { sendVerificationCode, verifyCode } from './firebase/api';
import { auth, googleProvider, microsoftProvider } from './firebase/config';
import { FIREBASE_LOGIN, UPDATE_USER_PROFILE } from './graphql/mutations';
import { CHECK_PHONE_NUMBER } from './graphql/queries';

const { Countdown } = Statistic;
const OTP_MODE = process.env.REACT_APP_OTP_MODE === 'true' ? true : false;

const Login = () => {
  const [step, setStep] = useState(1);
  const [contactForm] = useForm();
  const [metaForm] = useForm();
  const [otpForm] = useForm();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState<Dayjs | null>();
  const [inputData, setInputData] = useState<InputDataType>();
  const { location } = useRouter();
  const [reCaptcha, setReCaptcha] = useState<RecaptchaVerifier>();
  const [errors, setErrors] = useState<ErrorTypes>({});
  const { navigate } = useRouter();

  const { initializeAuth, dispatch } = useContext(AppContext) as AppContextType;

  const [verifyPhoneNumber, { loading: verifyLoading }] = useLazyQuery(
    CHECK_PHONE_NUMBER,
    {
      onError() {},
      fetchPolicy: 'no-cache',
    },
  );

  const [firebaseLoginMutate, { loading: loginLoading }] = useMutation(
    FIREBASE_LOGIN,
    {
      onError() {},
    },
  );

  const [updateUserProfileMutate, { loading: metaLoading }] = useMutation(
    UPDATE_USER_PROFILE,
    {
      onError() {},
    },
  );

  const setupReCaptcha = () => {
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      're-captcha-container',
      {
        size: 'invisible',
      },
    );
    setReCaptcha(recaptchaVerifier);
  };

  const [loginType, setLoginType] = useState<LoginType>();

  useEffect(() => {
    setupReCaptcha();
  }, []);

  const renderTitle = useMemo(() => {
    switch (step) {
      case 1:
        return "Tired of chasing data? Let's automate it.";
      case 2:
        return 'Welcome to ZinQ!';
      case 3:
        return 'OTP Verification';
      default:
        return '';
    }
  }, [step]);

  const renderDescription = useMemo(() => {
    const data: ContactFormType = contactForm.getFieldsValue([
      'prefix',
      'phone',
    ]);
    switch (step) {
      case 1:
        return `Sign in to your ZINQ account or create a new one to start collecting
        effortlessly.`;
      case 2:
        return 'Just one quick step to get you started.';
      case 3:
        return `OTP has been sent to ${data.countryCode?.trim()} ${data.phoneNumber}.`;
      default:
        return '';
    }
  }, [step]);

  const redirectUser = (
    user: User,
    accessToken: string,
    refreshToken: string,
    route: string,
  ) => {
    const from = location.state?.['from'];
    const redirectPath = from?.pathname !== ROUTES.LOGOUT ? from : route; // ignore if logout route
    initializeAuth(accessToken, user, refreshToken, redirectPath);
  };

  const handleLogin = (
    provider: GoogleAuthProvider | OAuthProvider,
    loginType: LoginType,
  ) => {
    setLoading(true);
    setLoginType(loginType);
    signInWithPopup(auth, provider)
      .then((result: UserCredential) => {
        const credential = OAuthProvider.credentialFromResult(result);

        if (!OTP_MODE) {
          const user: UserAuth & TokenType = result.user;
          firebaseLoginMutate({
            variables: {
              data: {
                name: user.displayName || '',
                token: user.accessToken || '',
                logInType: loginType,
                timezone: getDefaultTimezone(),
              },
            },
            onCompleted: async (res) => {
              const data = res.firebaseLogin;
              if (data) {
                // localStorage.setItem(TOKEN, data?.accessToken as string);
                // setInputData({
                //   ...inputData,
                //   user: data?.user as User,
                //   accessToken: data?.accessToken as string,
                //   refreshToken: data?.refreshToken as string,
                // });
                // setStep(4);
                // dispatch({
                //   type: AppActionType.setRedirectRoute,
                //   data: ROUTES.FORM_CREATE,
                // });
                if (!data.user?.isNewUser) {
                  redirectUser(
                    omit(data?.user, ['__typename']),
                    data.accessToken as string,
                    data.refreshToken as string,
                    ROUTES.MAIN,
                  );
                } else {
                  localStorage.setItem(TOKEN, data?.accessToken as string);
                  localStorage.setItem(
                    REFRESH_TOKEN,
                    data?.refreshToken as string,
                  );
                  setInputData({
                    ...inputData,
                    user: data?.user as User,
                    accessToken: data?.accessToken as string,
                    refreshToken: data?.refreshToken as string,
                  });
                  setStep(4);
                }
              }
            },
          });
        } else {
          // check if phone number is linked or not.
          if (!result.user.phoneNumber) {
            if (credential?.accessToken) {
              contactForm.setFieldValue('name', result.user.displayName);
              // handleAccessToken(credential.accessToken);
              setStep(2);
            }
          } else {
            const user: UserAuth & TokenType = result.user;
            firebaseLoginMutate({
              variables: {
                data: {
                  name: user.displayName || '',
                  token: user.accessToken || '',
                  timezone: getDefaultTimezone(),
                  logInType: loginType,
                },
              },
              onCompleted: async (res) => {
                const data = res.firebaseLogin;
                if (data) {
                  dispatch({
                    type: AppActionType.setRedirectRoute,
                    data: ROUTES.FORM_CREATE,
                  });
                  redirectUser(
                    omit(data?.user, ['__typename']),
                    data.accessToken as string,
                    data.refreshToken as string,
                    ROUTES.FORM_CREATE,
                  );
                }
              },
            });
          }
        }
      })
      .catch((error) => {
        messageContext.error(handleFirebaseError(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleContactFormFinish = (formValues?: ContactFormType) => {
    // setErrors({});
    // otpForm.resetFields();

    const inputValue = {
      name: formValues?.name || inputData?.name,
      phoneNumber: formValues?.phoneNumber || inputData?.phoneNumber,
      countryCode:
        formValues?.countryCode.trim() || inputData?.countryCode?.trim(),
    };

    verifyPhoneNumber({
      variables: {
        data: {
          countryCode: inputValue.countryCode as string,
          phoneNumber: inputValue.phoneNumber as string,
        },
      },
      onCompleted: async (res) => {
        if (!res.isPhoneNumberInUse?.isPhoneNumberInUsed) {
          try {
            setLoading(true);

            // send firebase OTP
            const response = await sendVerificationCode(
              `${inputValue.countryCode}${inputValue.phoneNumber}`,
              auth,
              reCaptcha as RecaptchaVerifier,
            );
            if (response) {
              messageContext.success(TOAST_MESSAGE.otpSent);
              const timerValue = dayjs().add(COMPANY_INFO.otpTimer, 'm');
              setInputData({
                ...inputData,
                ...inputValue,
                confirmationResult: response,
              });
              setTimer(timerValue);
              setStep(3);
            }
          } catch (error: unknown) {
            setErrors({
              phoneNumber: handleFirebaseError(error),
            });
          } finally {
            setLoading(false);
          }
        } else {
          setErrors({ phoneNumber: TOAST_MESSAGE.phoneInUsed });
        }
      },
    });
  };

  const handleOtpFormFinish = async (formValues: OTPFormType) => {
    try {
      setLoading(true);
      setErrors({});
      if (inputData?.confirmationResult) {
        const user: AuthUser & TokenType = await verifyCode(
          inputData.confirmationResult,
          formValues.otp,
        );
        if (user) {
          firebaseLoginMutate({
            variables: {
              data: {
                countryCode: inputData.countryCode,
                phoneNumber: inputData.phoneNumber as string,
                name: inputData.name as string,
                token: user?.accessToken as string,
                logInType: loginType as LoginType,
                timezone: getDefaultTimezone(),
              },
            },
            onCompleted: async (res) => {
              const data = res.firebaseLogin;
              if (!isEmpty(data)) {
                localStorage.setItem(TOKEN, data?.accessToken as string);
                setInputData({
                  ...inputData,
                  user: data?.user as User,
                  accessToken: data?.accessToken as string,
                  refreshToken: data?.refreshToken as string,
                });
                setStep(4);
              }
            },
          });
        }
      }
    } catch (error: unknown) {
      setErrors({
        otp: handleFirebaseError(error),
      });
    } finally {
      setLoading(false);
      otpForm.resetFields();
    }
  };

  const handleMetaFormFinish = (formValues?: UserProfileData) => {
    if (formValues) {
      updateUserProfileMutate({
        variables: { data: formValues },
        onCompleted: () => {
          // dispatch({
          //   type: AppActionType.setRedirectRoute,
          //   data: ROUTES.FORM_CREATE,
          // });
          redirectUser(
            inputData?.user as User,
            inputData?.accessToken as string,
            inputData?.refreshToken as string,
            ROUTES.FORM_CREATE,
          );
        },
      });
    } else {
      // dispatch({
      //   type: AppActionType.setRedirectRoute,
      //   data: ROUTES.FORM_CREATE,
      // });
      redirectUser(
        inputData?.user as User,
        inputData?.accessToken as string,
        inputData?.refreshToken as string,
        ROUTES.FORM_CREATE,
      );
    }
  };

  const handleFinishTimer = () => {
    setTimer(null);
  };

  const handleResendOTP = () => {
    handleContactFormFinish();
  };

  const handleFieldsChange = () => {
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const handleSingUpLink = () => {
    deleteCookie('email');
    deleteCookie('timer');
    navigate(ROUTES.REGISTER);
  };

  return (
    <section className="auth-wrapper">
      {step === 4 && (
        <CommonButton
          className="absolute top-16 right-24"
          onClick={() => handleMetaFormFinish()}
          size="small"
        >
          Skip
        </CommonButton>
      )}
      <div className="auth-form">
        <div className="flex justify-center">
          <Logo mode={FormThemeMode.Light} link={ROUTES.LOGIN} />
        </div>
        <Flex gap={4} vertical>
          <Title
            level={2}
            className="text-center semi-bold font-secondary text-content-primary"
          >
            Sign In
          </Title>
          <Paragraph className="mb-0 text-center text-content-tertiary">
            Sign in to your ZINQ account or create a new one to start collecting
            effortlessly.
          </Paragraph>
        </Flex>
        {step === 1 && (
          <>
            <Spin spinning={loading || loginLoading}>
              <Flex className="social-groups" gap={24} vertical>
                <Flex vertical gap={24}>
                  <Flex vertical gap={16}>
                    <CommonButton
                      type="text"
                      icon={<GoogleIcon />}
                      onClick={() =>
                        handleLogin(googleProvider, LoginType.Google)
                      }
                    >
                      Sign in with Google
                    </CommonButton>
                    <CommonButton
                      type="text"
                      icon={<MicrosoftIcon />}
                      onClick={() =>
                        handleLogin(microsoftProvider, LoginType.Microsoft)
                      }
                    >
                      Sign in with Microsoft
                    </CommonButton>
                  </Flex>
                  <Divider orientation="center" orientationMargin={24}>
                    <Paragraph className="mb-0 text-content-secondary text-meta">
                      OR
                    </Paragraph>
                  </Divider>
                  <CommonButton
                    type="primary"
                    onClick={() => navigate(ROUTES.LOGIN_EMAIL)}
                  >
                    Sign in with Email
                  </CommonButton>
                </Flex>
              </Flex>
            </Spin>
            <div>
              <Paragraph className="mb-0 text-center text-content-tertiary">
                Don’t have an account?{' '}
                <Link onClick={handleSingUpLink} underline>
                  Sign Up
                </Link>
              </Paragraph>
            </div>
          </>
        )}
        {step === 2 && (
          <Spin spinning={loading || verifyLoading}>
            <Form
              form={contactForm}
              name="contact-form"
              onFinish={handleContactFormFinish}
              layout="vertical"
              initialValues={{
                countryCode: '+91',
              }}
              scrollToFirstError
              onFieldsChange={handleFieldsChange}
            >
              <FormField
                name="name"
                label="Name"
                type={FormFieldType.TEXT}
                apiErrors={errors}
                rules={[
                  formValidation.required,
                  formValidation.whitespace,
                  formValidation.name,
                ]}
                inputProps={{ placeholder: 'Enter name' }}
              />
              <Row gutter={[12, 12]}>
                <Col span={24} md={8}>
                  <FormField
                    name="countryCode"
                    type={FormFieldType.SELECT}
                    label="Phone Number"
                    apiErrors={errors}
                    rules={OTP_MODE ? [formValidation.required] : []}
                    options={DROPDOWN_LIST.countryList}
                    inputProps={{ placeholder: 'Code', showSearch: true }}
                  />
                </Col>
                <Col span={24} md={16} className="flex items-end">
                  <FormField
                    name="phoneNumber"
                    label=""
                    type={FormFieldType.TEXT}
                    apiErrors={errors}
                    rules={
                      OTP_MODE
                        ? [formValidation.required, formValidation.number]
                        : [formValidation.number]
                    }
                    inputProps={{ placeholder: 'Enter phone' }}
                    formItemProps={{ className: 'w-full' }}
                  />
                </Col>
              </Row>
              <Form.Item className="mb-0">
                <Button
                  title="Continue"
                  htmlType="submit"
                  block
                  // border={false}
                />
              </Form.Item>
            </Form>
          </Spin>
        )}
        {step === 3 && (
          <Spin spinning={loading || loginLoading}>
            <Form
              form={otpForm}
              name="otp-form"
              onFinish={handleOtpFormFinish}
              layout="vertical"
              scrollToFirstError
              onFieldsChange={handleFieldsChange}
            >
              <Form.Item
                name="otp"
                rules={[formValidation.required]}
                className="mb-0"
                validateStatus={errors.otp ? 'error' : ''}
                help={errors.otp}
              >
                <Input.OTP length={6} />
              </Form.Item>
              <p className="text-neutrals-700 text-base mt-6 mb-24 flex gap-4">
                <Link
                  underline
                  disabled={timer ? true : false}
                  onClick={handleResendOTP}
                >
                  Resend OTP
                </Link>
                {timer && (
                  <Countdown
                    prefix={' in'}
                    value={Number(timer)}
                    format="mm:ss"
                    className="text-base"
                    onFinish={handleFinishTimer}
                  />
                )}
              </p>
              <Form.Item className="mb-0">
                <Button
                  title="Verify"
                  htmlType="submit"
                  block
                  // border={false}
                />
              </Form.Item>
            </Form>
          </Spin>
        )}
        {step === 4 && (
          <Spin spinning={metaLoading}>
            <Form
              form={metaForm}
              name="meta-form"
              onFinish={handleMetaFormFinish}
              layout="vertical"
              scrollToFirstError
            >
              <FormField
                name="industry"
                type={FormFieldType.SELECT}
                label="Which industry do you belong to?"
                apiErrors={errors}
                rules={[formValidation.required]}
                options={DROPDOWN_LIST.industryList}
                inputProps={{
                  placeholder: 'Select',
                  showSearch: true,
                  size: 'large',
                  suffixIcon: (
                    <CaretDown size={18} color="var(--content-quarternary)" />
                  ),
                  getPopupContainer: () =>
                    document.getElementById(
                      'industry-container',
                    ) as HTMLElement,
                }}
              />
              <div id="industry-container"></div>
              <FormField
                name="position"
                type={FormFieldType.SELECT}
                label="What is your current position or title?"
                apiErrors={errors}
                rules={[formValidation.required]}
                options={DROPDOWN_LIST.positionList}
                inputProps={{
                  placeholder: 'Select',
                  showSearch: true,
                  size: 'large',
                  suffixIcon: (
                    <CaretDown size={18} color="var(--content-quarternary)" />
                  ),
                  getPopupContainer: () =>
                    document.getElementById(
                      'position-container',
                    ) as HTMLElement,
                }}
              />
              <div id="position-container"></div>
              <FormField
                name="discover"
                type={FormFieldType.SELECT}
                label="How did you discover ZinQ?"
                apiErrors={errors}
                rules={[formValidation.required]}
                options={DROPDOWN_LIST.discoverList}
                inputProps={{
                  placeholder: 'Select',
                  showSearch: true,
                  size: 'large',
                  suffixIcon: (
                    <CaretDown size={18} color="var(--content-quarternary)" />
                  ),
                  getPopupContainer: () =>
                    document.getElementById(
                      'discover-container',
                    ) as HTMLElement,
                }}
              />
              <div id="discover-container"></div>
              <Form.Item>
                <CommonButton
                  type="primary"
                  htmlType="submit"
                  block
                  // border={false}
                  loading={metaLoading}
                >
                  {metaLoading ? 'Creating...' : 'Start Creating'}
                </CommonButton>
              </Form.Item>
            </Form>
          </Spin>
        )}
        <div id="re-captcha-container" className="d-none"></div>
      </div>
    </section>
  );
};

export default Login;
