import { nanoid } from 'nanoid';
import { AiTone, NotifyOn } from '../__generated__/graphql';
import {
  FormFieldType,
  FormFileType,
  FormSchemaType,
  FormThemeType,
  PageLayoutType,
  Palette,
} from '../modules/form/form.types';

/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  REGISTER: '/register',
  LOGIN_EMAIL: '/login/email',
  REGISTER_EMAIL: '/register/email',
  FORGET_PASSWORD: '/forgot-password',
  ONBOARDING: '/onboarding',
  RESET: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
  AUTHENTICATION: '/authentication',
  CONTACTS: '/contacts',
  ASSISTANT: '/assistant',
  SETTINGS: '/settings',
  NOTIFICATIONS: '/notifications',
  FORM_CREATE: '/form/create',
  FORM_TEMPLATES: '/form-templates',
  // editor routes
  EDITOR: '/editor',
  EDITOR_AI_WORKFLOW: '/editor/ai-workflow',
  EDITOR_KNOWLEDGE: '/editor/knowledge',
  EDITOR_PREVIEW: '/editor/design-preview',
  EDITOR_PUBLISH: '/editor/publish',
  // sharing routes
  SHARE: '/share',
  // result routes
  RESULTS: '/results',
  RESULTS_BY_QUESTIONS: '/results/by-questions',
  RESULTS_BY_PARTICIPANTS: '/results/by-participants',
  RESULTS_SETTING: '/results/settings',
  NOT_FOUND: '/404',
  FORM: '/form',
  ERROR: '/error',
  FORM_PREVIEW: '/p',
  CREATE_FORM_SLUG: '/create/form',
  ACCOUNT: '/account',
  INTEGRATION: '/account/integration',
  WORKSPACE_SETTING: '/account/setting',
  WORKSPACE_MEMBERS: '/account/member',
  PLAN_BILLING: '/account/plans',
};

export const REST_URL = {
  exportByParticipants: '/export-form-submissions',
};

/*  Modules */
export const MODULES = {
  HOME: 'Home',
  CONTACTS: 'Contacts',
  INTEGRATIONS: 'Integrations',
  ASSISTANT: 'Assistants',
  SETTINGS: 'Settings',
  NOTIFICATIONS: 'Notifications',
  PROFILE: 'Profile',
};

export const TOAST_MESSAGE = {
  somethingWentWrong: 'Something went wrong!',
  required: 'This field is required.',
  invalidInput: 'Invalid input.',
  invalidEmail: 'Invalid email.',
  otpSent: 'OTP sent successfully.',
  phoneInUsed: 'Phone number is already in use.',
  internalServerError: 'Internal server error.',
  success: 'Success.',
  copyText: 'Link copied to clipboard!',
  copyErrorText: 'Error while copying to clipboard!',
  previewNotAvailable: 'Preview not available!',
  invalidPassword:
    'Password must contain at least one uppercase letter, one number, one special character, and be at least 8 characters long',
};

export const TOKEN = 'TOKEN';
export const USER_WORKSPACE_ID = 'USER_WORKSPACE_ID';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SUBMISSION_ID = 'SUBMISSION_ID';
export const FORM_ID = 'FORM_ID';
export const USER = 'USER';
export const COMING_SOON_TEXT = 'Coming soon';

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.])[A-Za-z\d!@#$%^&*.]{8,}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  NON_KEY: /[^a-zA-Z0-9_\s]/g,
};

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINT = {
  cardBreakpoint: {
    xs: 24,
    sm: 12,
    md: 8,
    xl: 6,
  },
};

export const COMPANY_INFO = {
  cookieExpiry: 259200,
  otpTimer: 15,
  resendEmailTimer: 10,
};

export const ALLOWED_FILE_TYPES = {
  profileImage: 'image/png, image/jpeg, image/jpg',
  pageBgImage: 'image/png, image/jpeg, image/jpg, image/webp',
  ogShareImage: 'image/png, image/jpeg, image/jpg, image/webp',
};

export const ALLOWED_SIZE = {
  profileImage: 1,
  pageBgImage: 5,
  ogShareImage: 5,
};

export const MODAL_SIZE = {
  small: 300,
  medium: 500,
  large: 700,
  extraLarge: 900,
};

export const DEFAULT_AUTO_SAVE_TIME = 1000; //in seconds
export const DEFAULT_DATE_FORMAT = 'MM-DD-YYYY';
export const DEFAULT_TIME_FORMAT = 'hh:mm';
export const DEFAULT_DATE_TIME_FORMAT = 'MMM DD | hh:mm A';
export const RESULT_DATE_FORMAT = 'MMM DD YYYY';
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const initialPaginationFilter = {
  limit: DEFAULT_ITEMS_PER_PAGE,
  skip: 0,
};

export const DROPDOWN_LIST = {
  industryList: [
    { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Education', label: 'Education' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Human Resources', label: 'Human Resources' },
    { value: 'Product', label: 'Product' },
    { value: 'Other', label: 'Other' },
  ],
  positionList: [
    { value: 'CXO', label: 'CXO' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Director', label: 'Director' },
    { value: 'Employee', label: 'Employee' },
    { value: 'Freelancer', label: 'Freelancer' },
    { value: 'Student', label: 'Student' },
    { value: 'Consultant', label: 'Consultant' },
    { value: 'Other', label: 'Other' },
  ],
  discoverList: [
    { value: 'Website', label: 'Website' },
    { value: 'Online ad', label: 'Online ad' },
    { value: 'Social media', label: 'Social media' },
    { value: 'Word of mouth', label: 'Word of mouth' },
    { value: 'Other', label: 'Other' },
  ],
  countryList: [
    { value: '+1', label: '🇺🇸 +1' },
    { value: '+93', label: '🇦🇫 +93' },
    { value: '+355', label: '🇦🇱 +355' },
    { value: '+213', label: '🇩🇿 +213' },
    { value: '+376', label: '🇦🇩 +376' },
    { value: '+244', label: '🇦🇴 +244' },
    { value: '+54', label: '🇦🇷 +54' },
    { value: '+374', label: '🇦🇲 +374' },
    { value: '+297', label: '🇦🇼 +297' },
    { value: '+61', label: '🇦🇺 +61' },
    { value: '+43', label: '🇦🇹 +43' },
    { value: '+994', label: '🇦🇿 +994' },
    { value: '+973', label: '🇧🇭 +973' },
    { value: '+880', label: '🇧🇩 +880' },
    { value: '+375', label: '🇧🇾 +375' },
    { value: '+32', label: '🇧🇪 +32' },
    { value: '+501', label: '🇧🇿 +501' },
    { value: '+229', label: '🇧🇯 +229' },
    { value: '+975', label: '🇧🇹 +975' },
    { value: '+591', label: '🇧🇴 +591' },
    { value: '+387', label: '🇧🇦 +387' },
    { value: '+267', label: '🇧🇼 +267' },
    { value: '+55', label: '🇧🇷 +55' },
    { value: '+673', label: '🇧🇳 +673' },
    { value: '+359', label: '🇧🇬 +359' },
    { value: '+226', label: '🇧🇫 +226' },
    { value: '+257', label: '🇧🇮 +257' },
    { value: '+855', label: '🇰🇭 +855' },
    { value: '+237', label: '🇨🇲 +237' },
    { value: '+238', label: '🇨🇻 +238' },
    { value: '+236', label: '🇨🇫 +236' },
    { value: '+235', label: '🇹🇩 +235' },
    { value: '+56', label: '🇨🇱 +56' },
    { value: '+86', label: '🇨🇳 +86' },
    { value: '+57', label: '🇨🇴 +57' },
    { value: '+269', label: '🇰🇲 +269' },
    { value: '+242', label: '🇨🇬 +242' },
    { value: '+243', label: '🇨🇩 +243' },
    { value: '+506', label: '🇨🇷 +506' },
    { value: '+225', label: '🇨🇮 +225' },
    { value: '+385', label: '🇭🇷 +385' },
    { value: '+53', label: '🇨🇺 +53' },
    { value: '+357', label: '🇨🇾 +357' },
    { value: '+420', label: '🇨🇿 +420' },
    { value: '+45', label: '🇩🇰 +45' },
    { value: '+253', label: '🇩🇯 +253' },
    { value: '+670', label: '🇹🇱 +670' },
    { value: '+593', label: '🇪🇨 +593' },
    { value: '+20', label: '🇪🇬 +20' },
    { value: '+503', label: '🇸🇻 +503' },
    { value: '+240', label: '🇬🇶 +240' },
    { value: '+291', label: '🇪🇷 +291' },
    { value: '+372', label: '🇪🇪 +372' },
    { value: '+251', label: '🇪🇹 +251' },
    { value: '+500', label: '🇫🇰 +500' },
    { value: '+298', label: '🇫🇴 +298' },
    { value: '+679', label: '🇫🇯 +679' },
    { value: '+358', label: '🇫🇮 +358' },
    { value: '+33', label: '🇫🇷 +33' },
    { value: '+594', label: '🇬🇫 +594' },
    { value: '+689', label: '🇵🇫 +689' },
    { value: '+241', label: '🇬🇦 +241' },
    { value: '+220', label: '🇬🇲 +220' },
    { value: '+995', label: '🇬🇪 +995' },
    { value: '+49', label: '🇩🇪 +49' },
    { value: '+233', label: '🇬🇭 +233' },
    { value: '+350', label: '🇬🇮 +350' },
    { value: '+30', label: '🇬🇷 +30' },
    { value: '+299', label: '🇬🇱 +299' },
    { value: '+502', label: '🇬🇹 +502' },
    { value: '+224', label: '🇬🇳 +224' },
    { value: '+245', label: '🇬🇼 +245' },
    { value: '+592', label: '🇬🇾 +592' },
    { value: '+509', label: '🇭🇹 +509' },
    { value: '+504', label: '🇭🇳 +504' },
    { value: '+852', label: '🇭🇰 +852' },
    { value: '+36', label: '🇭🇺 +36' },
    { value: '+354', label: '🇮🇸 +354' },
    { value: '+91', label: '🇮🇳 +91' },
    { value: '+62', label: '🇮🇩 +62' },
    { value: '+98', label: '🇮🇷 +98' },
    { value: '+964', label: '🇮🇶 +964' },
    { value: '+353', label: '🇮🇪 +353' },
    { value: '+972', label: '🇮🇱 +972' },
    { value: '+39', label: '🇮🇹 +39' },
    { value: '+81', label: '🇯🇵 +81' },
    { value: '+962', label: '🇯🇴 +962' },
    { value: '+7', label: '🇰🇿 +7' },
    { value: '+254', label: '🇰🇪 +254' },
    { value: '+686', label: '🇰🇮 +686' },
    { value: '+383', label: '🇽🇰 +383' },
    { value: '+965', label: '🇰🇼 +965' },
    { value: '+996', label: '🇰🇬 +996' },
    { value: '+856', label: '🇱🇦 +856' },
    { value: '+371', label: '🇱🇻 +371' },
    { value: '+961', label: '🇱🇧 +961' },
    { value: '+266', label: '🇱🇸 +266' },
    { value: '+231', label: '🇱🇷 +231' },
    { value: '+218', label: '🇱🇾 +218' },
    { value: '+423', label: '🇱🇮 +423' },
    { value: '+370', label: '🇱🇹 +370' },
    { value: '+352', label: '🇱🇺 +352' },
    { value: '+853', label: '🇲🇴 +853' },
    { value: '+389', label: '🇲🇰 +389' },
    { value: '+261', label: '🇲🇬 +261' },
    { value: '+265', label: '🇲🇼 +265' },
    { value: '+60', label: '🇲🇾 +60' },
    { value: '+960', label: '🇲🇻 +960' },
    { value: '+223', label: '🇲🇱 +223' },
    { value: '+356', label: '🇲🇹 +356' },
    { value: '+692', label: '🇲🇭 +692' },
    { value: '+596', label: '🇲🇶 +596' },
    { value: '+222', label: '🇲🇷 +222' },
    { value: '+230', label: '🇲🇺 +230' },
    { value: '+262', label: '🇾🇹 +262' },
    { value: '+52', label: '🇲🇽 +52' },
    { value: '+691', label: '🇫🇲 +691' },
    { value: '+373', label: '🇲🇩 +373' },
    { value: '+377', label: '🇲🇨 +377' },
    { value: '+976', label: '🇲🇳 +976' },
    { value: '+382', label: '🇲🇪 +382' },
    { value: '+212', label: '🇲🇦 +212' },
    { value: '+258', label: '🇲🇿 +258' },
    { value: '+95', label: '🇲🇲 +95' },
    { value: '+264', label: '🇳🇦 +264' },
    { value: '+674', label: '🇳🇷 +674' },
    { value: '+977', label: '🇳🇵 +977' },
    { value: '+31', label: '🇳🇱 +31' },
    { value: '+599', label: '🇳🇱 +599' },
    { value: '+687', label: '🇳🇨 +687' },
    { value: '+64', label: '🇳🇿 +64' },
    { value: '+505', label: '🇳🇮 +505' },
    { value: '+227', label: '🇳🇪 +227' },
    { value: '+234', label: '🇳🇬 +234' },
    { value: '+683', label: '🇳🇺 +683' },
    { value: '+850', label: '🇰🇵 +850' },
    { value: '+47', label: '🇳🇴 +47' },
    { value: '+968', label: '🇴🇲 +968' },
    { value: '+92', label: '🇵🇰 +92' },
    { value: '+680', label: '🇵🇼 +680' },
    { value: '+970', label: '🇵🇸 +970' },
    { value: '+507', label: '🇵🇦 +507' },
    { value: '+675', label: '🇵🇬 +675' },
    { value: '+595', label: '🇵🇾 +595' },
    { value: '+51', label: '🇵🇪 +51' },
    { value: '+63', label: '🇵🇭 +63' },
    { value: '+48', label: '🇵🇱 +48' },
    { value: '+351', label: '🇵🇹 +351' },
    { value: '+974', label: '🇶🇦 +974' },
    { value: '+40', label: '🇷🇴 +40' },
    { value: '+7', label: '🇷🇺 +7' },
    { value: '+250', label: '🇷🇼 +250' },
    { value: '+685', label: '🇼🇸 +685' },
    { value: '+378', label: '🇸🇲 +378' },
    { value: '+239', label: '🇸🇹 +239' },
    { value: '+966', label: '🇸🇦 +966' },
    { value: '+221', label: '🇸🇳 +221' },
    { value: '+381', label: '🇷🇸 +381' },
    { value: '+248', label: '🇸🇨 +248' },
    { value: '+232', label: '🇸🇱 +232' },
    { value: '+65', label: '🇸🇬 +65' },
    { value: '+421', label: '🇸🇰 +421' },
    { value: '+386', label: '🇸🇮 +386' },
    { value: '+677', label: '🇸🇧 +677' },
    { value: '+252', label: '🇸🇴 +252' },
    { value: '+27', label: '🇿🇦 +27' },
    { value: '+82', label: '🇰🇷 +82' },
    { value: '+34', label: '🇪🇸 +34' },
    { value: '+94', label: '🇱🇰 +94' },
    { value: '+249', label: '🇸🇩 +249' },
    { value: '+597', label: '🇸🇷 +597' },
    { value: '+268', label: '🇸🇿 +268' },
    { value: '+46', label: '🇸🇪 +46' },
    { value: '+41', label: '🇨🇭 +41' },
    { value: '+963', label: '🇸🇾 +963' },
    { value: '+886', label: '🇹🇼 +886' },
    { value: '+992', label: '🇹🇯 +992' },
    { value: '+255', label: '🇹🇿 +255' },
    { value: '+66', label: '🇹🇭 +66' },
    { value: '+228', label: '🇹🇬 +228' },
    { value: '+676', label: '🇹🇴 +676' },
    { value: '+216', label: '🇹🇳 +216' },
    { value: '+90', label: '🇹🇷 +90' },
    { value: '+993', label: '🇹🇲 +993' },
    { value: '+688', label: '🇹🇻 +688' },
    { value: '+256', label: '🇺🇬 +256' },
    { value: '+380', label: '🇺🇦 +380' },
    { value: '+971', label: '🇦🇪 +971' },
    { value: '+44', label: '🇬🇧 +44' },
    { value: '+598', label: '🇺🇾 +598' },
    { value: '+998', label: '🇺🇿 +998' },
    { value: '+678', label: '🇻🇺 +678' },
    { value: '+58', label: '🇻🇪 +58' },
    { value: '+84', label: '🇻🇳 +84' },
    { value: '+681', label: '🇼🇫 +681' },
    { value: '+967', label: '🇾🇪 +967' },
    { value: '+260', label: '🇿🇲 +260' },
    { value: '+263', label: '🇿🇼 +263' },
  ],
  personaList: [
    { value: AiTone.Energetic, label: 'Energetic' },
    { value: AiTone.Sassy, label: 'Sassy' },
    { value: AiTone.Witty, label: 'Witty' },
    { value: AiTone.Professional, label: 'Professional' },
    { value: AiTone.Casual, label: 'Causal' },
    { value: AiTone.Concise, label: 'Concise' },
  ],
  fileCheckBoxList: [
    { value: FormFileType.DOCUMENT, label: 'Document (.pdf, .csv, .docx)' },
    { value: FormFileType.AUDIO, label: 'Audio (.mp3)' },
    { value: FormFileType.VIDEO, label: 'Video (.mp4)' },
    { value: FormFileType.IMAGE, label: 'Images (.png, .svg, .jpg)' },
  ],
  ratingList: [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
  ],
  minRatingList: [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
  ],
  maxRatingList: [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 100, label: '100' },
  ],
  allowedDateList: [
    { value: 1, label: 'Past' },
    { value: 2, label: 'Future' },
  ],
  notifyMeList: [
    { value: NotifyOn.Email, label: 'Email' },
    { value: 'SMS', label: 'SMS (Coming Soon)', isDisabled: true },
    { value: 'WHATSAPP', label: 'WhatsApp (Coming Soon)', isDisabled: true },
    { value: 'ZAPIER', label: 'Zapier (Coming Soon)', isDisabled: true },
  ],
  notifyWhenList: [
    {
      value: 'ONCE_A_DAY',
      label: 'Once a day',
      description: 'Receive summary of all submission once  a day.',
      isPremium: false,
    },
    {
      value: 'ON_SUBMISSION',
      label: 'On submission',
      description: 'Whenever someone fills the form.',
      isPremium: true,
    },
  ],
  defaultFormOptionList: ['Option 1', 'Option 2', 'Option 3'],
};

export const FORM_CONTROLS = [
  { value: FormFieldType.SHORT_TEXT, label: 'Short Text', isPremium: false },
  { value: FormFieldType.LONG_TEXT, label: 'Long Text', isPremium: false },
  { value: FormFieldType.SELECT, label: 'Select', isPremium: false },
  {
    value: FormFieldType.MULTI_SELECT,
    label: 'Multi Select',
    isPremium: false,
  },
  { value: FormFieldType.NUMBER, label: 'Number', isPremium: false },
  { value: FormFieldType.RATE, label: 'Rating', isPremium: false },
  {
    value: FormFieldType.LINEAR_SCALE,
    label: 'Linear Scale',
    isPremium: false,
  },
  { value: FormFieldType.FILE, label: 'Upload', isPremium: false },
  { value: FormFieldType.DATE, label: 'Date', isPremium: false },
  { value: FormFieldType.TIME, label: 'Time', isPremium: false },
  { value: FormFieldType.EMAIL, label: 'Email', isPremium: false },
  {
    value: FormFieldType.PHONE_NUMBER,
    label: 'Phone Number',
    isPremium: false,
  },
  { value: FormFieldType.CONTACT_INFO, label: 'Contact', isPremium: false },
  { value: FormFieldType.ADDRESS, label: 'Address', isPremium: false },
  { value: FormFieldType.PAYMENT, label: 'Payment', isPremium: true },
  { value: FormFieldType.CALENDAR, label: 'Calendar', isPremium: true },
  { value: FormFieldType.DATE_RANGE, label: 'Date Range', isPremium: true },
  {
    value: FormFieldType.PICTURE_CHOICE,
    label: 'Picture Choice',
    isPremium: true,
  },
];

export const STATIC_FORM_FIELDS: Record<string, FormSchemaType[]> = {
  address: [
    {
      answer_store_key: 'address_line_1',
      name: 'Address Line 1',
      answer_type: FormFieldType.SHORT_TEXT,
      question_description: 'Address',
      question_text: 'Address',
      is_required: true,
    },
    {
      answer_store_key: 'address_line_2',
      name: 'Address Line 2',
      answer_type: FormFieldType.SHORT_TEXT,
      question_description: 'Address Line 2',
      question_text: 'Address Line 2',
      is_required: false,
    },
    {
      answer_store_key: 'city_town',
      name: 'City/Town',
      question_description: 'City/Town',
      answer_type: FormFieldType.SHORT_TEXT,
      question_text: 'City/Town',
      is_required: true,
    },
    {
      answer_store_key: 'state_region_province',
      name: 'State/Region/Province',
      question_description: 'State/Region/Province',
      answer_type: FormFieldType.SHORT_TEXT,
      question_text: 'State/Region/Province',
      is_required: true,
    },
    {
      answer_store_key: 'zip_post_code',
      name: 'Zip/Post Code',
      question_description: 'Zip/Post code',
      answer_type: FormFieldType.SHORT_TEXT,
      question_text: 'Zip/Post Code',
      is_required: true,
    },
    {
      answer_store_key: 'country',
      name: 'Country',
      question_description: 'Country',
      answer_type: FormFieldType.SHORT_TEXT,
      question_text: 'Country',
      is_required: true,
    },
  ],
  contactInfo: [
    {
      answer_store_key: 'first_name',
      name: 'First Name',
      question_description: 'First Name',
      answer_type: FormFieldType.SHORT_TEXT,
      question_text: 'First Name',
      is_required: true,
    },
    {
      answer_store_key: 'last_name',
      name: 'Last Name',
      question_description: 'Last Name',
      answer_type: FormFieldType.SHORT_TEXT,
      question_text: 'Last Name',
      is_required: true,
    },
    {
      answer_store_key: 'email',
      name: 'Email',
      question_description: 'Email',
      answer_type: FormFieldType.EMAIL,
      question_text: 'Email',
      is_required: true,
    },
    {
      answer_store_key: 'company_name',
      name: 'Company',
      question_description: 'Company',
      answer_type: FormFieldType.SHORT_TEXT,
      question_text: 'Company Name',
      is_required: true,
    },
    {
      answer_store_key: 'phone',
      name: 'Phone',
      question_description: 'Phone',
      question_text: 'Phone Number',
      answer_type: FormFieldType.PHONE_NUMBER,
      is_required: true,
      nested: [
        {
          answer_store_key: 'country_code',
          question_description: 'country code.',
          answer_type: FormFieldType.SELECT,
          is_required: true,
          hidden: false,
          options: DROPDOWN_LIST.countryList,
        },
        {
          answer_store_key: 'phone',
          question_description: 'Number.',
          answer_type: FormFieldType.NUMBER,
          is_required: true,
          hidden: false,
          config: {
            regex: REGEX.PHONE,
          },
        },
      ],
    },
  ],
  phone: [
    {
      answer_store_key: 'country_code',
      question_description: 'country code.',
      answer_type: FormFieldType.SELECT,
      is_required: true,
      hidden: false,
      options: DROPDOWN_LIST.countryList,
    },
    {
      answer_store_key: 'phone',
      question_description: 'Number.',
      answer_type: FormFieldType.NUMBER,
      is_required: true,
      hidden: false,
      config: {
        regex: REGEX.PHONE,
      },
    },
  ],
  defaultWelcomePage: [
    {
      answer_store_key: FormFieldType.WELCOME_PAGE,
      question_id: nanoid(),
      body: `We hope you're settling in nicely! Your feedback on the onboarding process is super important to us. Let us know what you think so we can make it even better for the next crew!`,
      title: 'Welcome!',
      cta_name: 'Share your thoughts',
      is_display: true,
      answer_type: FormFieldType.WELCOME_PAGE,
      page_layout: PageLayoutType.PAGE_FILLED,
    },
  ],
  defaultEndPage: [
    {
      answer_store_key: FormFieldType.END_PAGE,
      question_id: nanoid(),
      body: `We’re grateful for your feedback as we work to create a great onboarding experience. Your input will help us make every new team member feel welcome and supported.`,
      title: 'Thanks for Sharing Your Thoughts!',
      cta_name: '',
      cta_link: '',
      is_display: true,
      answer_type: FormFieldType.END_PAGE,
      page_layout: PageLayoutType.PAGE_FILLED,
    },
  ],
  defaultQuestion: [
    {
      answer_store_key: '',
      question_id: nanoid(),
      question_description: 'Untitled',
      question_title: 'Untitled',
      question_text: 'Untitled',
      answer_type: FormFieldType.SHORT_TEXT,
      is_required: true,
      placeholder: 'Type here...',
      config: {
        uiStyle: FormFieldType.SHORT_TEXT,
      },
    },
  ],
};

export const getSliderMarks = (count: number) => {
  const obj: { [key: number]: string } = {};

  if (count <= 0) return obj;

  if (count === 100) {
    for (let i = 0; i <= count; i += 10) {
      obj[i] = `${i}`;
    }
  } else {
    for (let i = 0; i <= count; i++) {
      obj[i] = `${i}`;
    }
  }

  return obj;
};

export const getThemeBackground = (type: FormThemeType, style: Palette) => {
  switch (type) {
    case FormThemeType.SOLID:
      return {
        backgroundColor: style?.bgPrimary,
      };
    case FormThemeType.GRADIENT:
      return {
        background: `linear-gradient(${style?.gradientDegree || 140}deg, ${style?.bgPrimary} 1.27%, ${style.bgSecondary} 70.38%)`,
      };
    case FormThemeType.PATTERN:
      return {
        backgroundImage: `url('${style?.bgImage}')`,
        backgroundColor: style?.bgPrimary,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };
    default:
      break;
  }
};

export const graphQLErrorsCode = {
  INVALID_LOGIN_CREDENTIALS: 'INVALID_LOGIN_CREDENTIALS',
};
