import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { ROUTES } from '../../../common/constants';
import LoaderComponent from '../../../components/common/LoaderComponent';
import useRouter from '../../../hooks/useRouter';
import { USE_FORM_TEMPLATE } from '../graphql/mutations';

export default function CreateFormBySlug() {
  const { params, navigate } = useRouter();
  const { slug } = params;

  const [useFormTemplateMutate, { loading }] = useMutation(USE_FORM_TEMPLATE, {
    onError: () => {
      navigate(ROUTES.MAIN);
    },
    onCompleted: (res) => {
      navigate(`${ROUTES.EDITOR}?id=${res.useFormTemplate?.data?.id}`);
    },
  });

  useEffect(() => {
    if (slug) {
      useFormTemplateMutate({
        variables: {
          data: {
            formTemplateSlug: slug,
          },
        },
      });
    }
  }, [slug]);

  if (loading) return <LoaderComponent />;
  return null;
}
