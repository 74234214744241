import { useQuery } from '@apollo/client';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import {
  Building,
  DownloadSimple,
  EnvelopeSimple,
  Export,
  Paperclip,
  Phone,
} from '@phosphor-icons/react';
import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { compact, isArray, isEmpty, map, startCase, trim } from 'lodash';
import { useContext, useRef, useState } from 'react';
import generatePDF, { Margin } from 'react-to-pdf';
import { AppContext } from '../../AppContext';
import { RESULT_DATE_FORMAT } from '../../common/constants';
import { getFileDownloadName } from '../../common/utils';
import EmptyStatePage from '../../components/common/EmptyStatePage';
import CommonButton from '../../components/primitives/CommonButton';
import useQueryParams from '../../hooks/useQueryParams';
import { AppContextType } from '../../types/appContext.type';
import FormLoading from '../form/editor/utils/FormLoading';
import { FormFieldType, FormSchemaType } from '../form/form.types';
import { GET_RESULT_BY_QUESTION } from '../form/graphql/queries';

export default function ByQuestions() {
  const customColors = [
    '#C4B1E8',
    '#F9A474',
    '#C2D28A',
    '#FEDF6F',
    '#F581A4',
    '#595BB2',
    '#B29854',
    '#B2597F',
    '#59ADB2',
    '#1463B8',
  ];
  const customBar = ['#6542DC'];

  const { getQueryParam } = useQueryParams();
  const formId = getQueryParam('id') as string;
  const targetRef = useRef<HTMLDivElement>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const {
    state: { formSettings },
  } = useContext(AppContext) as AppContextType;

  const { data, loading } = useQuery(GET_RESULT_BY_QUESTION, {
    variables: {
      where: {
        formId,
      },
      filter: {
        isTest: false,
      },
    },
    fetchPolicy: 'network-only',
  });

  const renderComponents = (item: FormSchemaType) => {
    const dataItem =
      data?.formSubmissionsByQuestion?.data?.questionResults?.find(
        (structure) => structure?.key === item.question_id,
      );

    switch (dataItem?.type) {
      case FormFieldType.DATE:
        return (
          <div className="feedback-wrapper">
            {map(dataItem.data, (obj) => (
              <Flex gap={6} vertical className="feedback-item">
                <Paragraph className="mb-0 text-content-primary medium">
                  {trim(obj?.name || '') || '-'}
                </Paragraph>

                {obj?.value && (
                  <Paragraph className="text-content-secondary mb-0">
                    {dayjs(obj.value).format(RESULT_DATE_FORMAT)}
                  </Paragraph>
                )}
              </Flex>
            ))}
          </div>
        );
      case FormFieldType.SHORT_TEXT:
      case FormFieldType.LONG_TEXT:
      case FormFieldType.EMAIL:
      case FormFieldType.NUMBER:
      case FormFieldType.PHONE_NUMBER:
      case FormFieldType.TIME:
        return (
          <div className="feedback-wrapper">
            {map(dataItem.data, (obj) => (
              <Flex gap={6} vertical className="feedback-item">
                <Paragraph className="mb-0 text-content-primary medium">
                  {trim(obj?.name || '') || '-'}
                </Paragraph>

                {obj?.value && (
                  <Paragraph className="text-content-secondary mb-0">
                    {trim(obj?.value || '') || '-'}
                  </Paragraph>
                )}
              </Flex>
            ))}
          </div>
        );

      case FormFieldType.ADDRESS:
        return (
          <div className="feedback-wrapper">
            {map(dataItem.data, (obj) => {
              const addressParts = [
                obj?.value.address_line_1,
                obj?.value?.address_line_2,
                obj?.value?.city_town,
                obj?.value?.state_region_province,
                obj?.value?.zip_post_code,
                obj?.value?.country,
              ];
              return (
                <Flex gap={6} vertical className="feedback-item">
                  <Paragraph className="mb-0 text-content-primary medium">
                    {trim(obj?.name || '') || '-'}
                  </Paragraph>
                  {!isEmpty(addressParts) && (
                    <Paragraph className="text-content-secondary mb-0">
                      {compact(addressParts).join(', ')}
                    </Paragraph>
                  )}
                </Flex>
              );
            })}
          </div>
        );
      case FormFieldType.CONTACT_INFO:
        return (
          <div className="feedback-wrapper">
            {map(dataItem.data, (obj) => {
              const fullName = `${obj?.value?.first_name || ''} ${obj?.value?.last_name || ''}`;
              return (
                <Flex gap={6} vertical className="feedback-item">
                  <Paragraph className="mb-0 text-content-primary medium">
                    {trim(fullName) || '-'}
                  </Paragraph>
                  <Flex gap={16} wrap>
                    {obj?.value?.phone?.trim() && (
                      <Flex align="center" gap={2}>
                        <Phone
                          size={14}
                          color="var(--content-primary)"
                          weight="fill"
                        />
                        <Paragraph className="text-content-secondary mb-0">
                          {obj?.value?.phone?.trim()}
                        </Paragraph>
                      </Flex>
                    )}
                    {obj?.value?.email?.trim() && (
                      <Flex align="center" gap={2}>
                        <EnvelopeSimple
                          size={14}
                          color="var(--content-primary)"
                          weight="fill"
                        />
                        <Paragraph className="text-content-secondary mb-0">
                          {obj?.value?.email?.trim() || '-'}
                        </Paragraph>
                      </Flex>
                    )}
                    {obj?.value?.company_name?.trim() && (
                      <Flex align="center" gap={2}>
                        <Building
                          size={14}
                          color="var(--content-primary)"
                          weight="fill"
                        />
                        <Paragraph className="text-content-secondary mb-0">
                          {obj?.value?.company_name?.trim() || '-'}
                        </Paragraph>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </div>
        );

      case FormFieldType.FILE:
        return (
          <ul className="photo-list">
            {isArray(dataItem.data) &&
              map(dataItem.data, (obj) => (
                <li>
                  <span className="name text-neutrals-8 text-sm">
                    {trim(obj?.name || '') || '-'}
                  </span>
                  {isArray(obj?.value) &&
                    map(obj?.value, (file, idx) => {
                      return (
                        <a href={file?.url} target="_blank" rel="noreferrer">
                          <Flex gap={4} align="center" wrap>
                            <Paperclip color="var(--content-secondary)" />
                            <Flex align="center" gap={8}>
                              <Paragraph className="mb-0 text-content-primary">
                                {file?.fileName}
                              </Paragraph>

                              <DownloadSimple
                                color="var(--content-secondary)"
                                size={20}
                              />
                            </Flex>
                          </Flex>
                        </a>
                      );
                    })}
                </li>
              ))}
          </ul>
        );

      case FormFieldType.LINEAR_SCALE:
      case FormFieldType.SELECT:
      case FormFieldType.MULTI_SELECT:
        return (
          <div className="graph-wrapper">
            <ResponsiveBar
              data={map(dataItem.data, (graph) => {
                return {
                  key: startCase(graph?.value) || '',
                  value: graph?.count || 0,
                };
              })}
              keys={['value']}
              indexBy="key"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              minValue={0}
              maxValue={4}
              groupMode="grouped"
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={customBar}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: 'fries',
                  },
                  id: 'dots',
                },
                {
                  match: {
                    id: 'sandwich',
                  },
                  id: 'lines',
                },
              ]}
              borderWidth={0}
              borderColor={{
                from: 'color',
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
                truncateTickAt: 0,
              }}
              totalsOffset={11}
              labelTextColor="#ffffff"
              legends={[]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                e.id + ': ' + e.formattedValue + ' in key: ' + e.indexValue
              }
            />
          </div>
        );

      case FormFieldType.RATE:
        return (
          <div className="graph-wrapper">
            <ResponsivePie
              data={map(dataItem.data, (graph) => {
                return {
                  id: `${graph?.value} - Rating`,
                  label: `${graph?.value} - Rating`,
                  value: graph?.count || 0,
                };
              })}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              startAngle={-13}
              innerRadius={0.6}
              padAngle={1}
              activeOuterRadiusOffset={8}
              colors={customColors}
              borderColor={{ theme: 'background' }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#343b3f"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsRadiusOffset={0.45}
              arcLabelsTextColor={{
                from: 'color',
              }}
              // defs={[
              //   {
              //     id: 'dots',
              //     type: 'patternDots',
              //     background: 'inherit',
              //     color: 'rgba(255, 255, 255, 0.3)',
              //     size: 4,
              //     padding: 1,
              //     stagger: true,
              //   },
              //   {
              //     id: 'lines',
              //     type: 'patternLines',
              //     background: 'inherit',
              //     color: 'rgba(255, 255, 255, 0.3)',
              //     rotation: -45,
              //     lineWidth: 6,
              //     spacing: 10,
              //   },
              // ]}
              // fill={[
              //   {
              //     match: {
              //       id: 'ruby',
              //     },
              //     id: 'dots',
              //   },
              //   {
              //     match: {
              //       id: 'c',
              //     },
              //     id: 'dots',
              //   },
              //   {
              //     match: {
              //       id: 'go',
              //     },
              //     id: 'dots',
              //   },
              //   {
              //     match: {
              //       id: 'python',
              //     },
              //     id: 'dots',
              //   },
              //   {
              //     match: {
              //       id: 'scala',
              //     },
              //     id: 'lines',
              //   },
              //   {
              //     match: {
              //       id: 'lisp',
              //     },
              //     id: 'lines',
              //   },
              //   {
              //     match: {
              //       id: 'elixir',
              //     },
              //     id: 'lines',
              //   },
              //   {
              //     match: {
              //       id: 'javascript',
              //     },
              //     id: 'lines',
              //   },
              // ]}
              legends={[
                {
                  anchor: 'right',
                  direction: 'column',
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 16,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000',
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        );

      default:
        break;
    }
  };

  const handlePdfExport = async () => {
    const fileName = getFileDownloadName(formSettings?.title || '');
    try {
      setUploading(true);
      await generatePDF(targetRef, {
        filename: fileName,
        page: { margin: Margin.SMALL },
      });
    } catch (e) {
      return e;
    } finally {
      setUploading(false);
    }
  };

  if (loading) {
    return <FormLoading />;
  }
  if (isEmpty(data?.formSubmissionsByQuestion?.data?.questions)) {
    return <EmptyStatePage />;
  }
  return (
    <div className="result-container">
      <Flex align="center" justify="space-between" gap={4} className="mb-12">
        <Title level={2} className="font-secondary">
          {`Responses (${data?.formSubmissionsByQuestion?.data?.totalSubmissions || 0})`}
        </Title>
        <CommonButton
          onClick={handlePdfExport}
          icon={<Export color="var(--content-secondary)" />}
          loading={uploading}
          disabled={loading}
        >
          Export
        </CommonButton>
      </Flex>
      <div className="overview-wrapper" ref={targetRef}>
        {map(
          data?.formSubmissionsByQuestion?.data?.questions,
          (item: FormSchemaType, index) => {
            return (
              <div className="summary-wrapper">
                <Paragraph className="mb-0 text-content-primary text-m semi-bold">
                  {`${index + 1}. ${item?.question_text}`}
                </Paragraph>

                {renderComponents(item)}
              </div>
            );
          },
        )}
      </div>
    </div>
  );
}
