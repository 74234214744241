import { useMutation } from '@apollo/client';
import { Flex, Form, Input, Spin, Statistic } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Link from 'antd/es/typography/Link';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { omit } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import {
  FormThemeMode,
  LoginUserInput,
  User,
} from '../../__generated__/graphql';
import { graphQLErrorsCode, ROUTES } from '../../common/constants';
import { deleteCookie, formValidation, setCookie } from '../../common/utils';
import Logo from '../../components/common/Logo';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { USER_LOGIN } from './graphql/mutations';

const { Countdown } = Statistic;

const EmailLogin = () => {
  const { location, navigate } = useRouter();
  const [form] = useForm();

  const { initializeAuth, dispatch } = useContext(AppContext) as AppContextType;
  const [userLoginMutate, { loading }] = useMutation(USER_LOGIN, {
    onError: () => {},
  });

  const redirectUser = (
    user: User,
    accessToken: string,
    refreshToken: string,
    route: string,
  ) => {
    const from = location.state?.['from'];
    const redirectPath = from?.pathname !== ROUTES.LOGOUT ? from : route; // ignore if logout route
    initializeAuth(accessToken, user, refreshToken, redirectPath);
  };

  const handleLogin = (payload: LoginUserInput) => {
    const { email, password } = payload;
    userLoginMutate({
      variables: {
        data: { email, password },
      },
      onCompleted: (res) => {
        const data = res.loginUser;
        if (data?.user?.emailVerifiedAt) {
          redirectUser(
            omit(data?.user, ['__typename']),
            data?.accessToken || '',
            data?.refreshToken || '',
            ROUTES.MAIN,
          );
        } else {
          setCookie('email', email, null);
          navigate(ROUTES.REGISTER_EMAIL);
        }
      },
      onError: (err) => {
        if (
          err.graphQLErrors?.[0]?.extensions?.code ===
          graphQLErrorsCode.INVALID_LOGIN_CREDENTIALS
        ) {
          form.setFields([
            {
              name: 'password',
              errors: [err?.message],
            },
          ]);
        }
      },
    });
  };

  const handleSingUpLink = () => {
    deleteCookie('email');
    deleteCookie('timer');
    navigate(ROUTES.REGISTER);
  };

  return (
    <section className="auth-wrapper">
      <div className="auth-form">
        <div className="flex justify-center">
          <Logo mode={FormThemeMode.Light} link={ROUTES.LOGIN} />
        </div>
        <Flex gap={4} vertical>
          <Title
            level={2}
            className="text-center semi-bold font-secondary text-content-primary"
          >
            Sign In
          </Title>
          <Paragraph className="mb-0 text-center text-content-tertiary">
            Sign in to your ZINQ account or create a new one to start collecting
            effortlessly.
          </Paragraph>
        </Flex>
        <Spin spinning={loading}>
          <div className="social-groups">
            <Form
              form={form}
              autoComplete="off"
              name="register"
              onFinish={handleLogin}
              layout="vertical"
              scrollToFirstError
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[formValidation.required, formValidation.email]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>

              <div>
                <Form.Item
                  name="password"
                  label="Password"
                  className="mb-4"
                  rules={[formValidation.required, formValidation.whitespace]}
                >
                  <Input.Password placeholder="Enter password" />
                </Form.Item>
                <Flex className="mb-24" justify="flex-end">
                  <Link onClick={() => navigate(ROUTES.FORGET_PASSWORD)}>
                    <Paragraph className="mb-0 text-content-secondary text-underline semi-bold text-meta">
                      Forgot Password?
                    </Paragraph>
                  </Link>
                </Flex>
              </div>
              <Form.Item className="mb-0">
                <CommonButton type="primary" htmlType="submit" block>
                  Sign In
                </CommonButton>
              </Form.Item>
            </Form>
          </div>
        </Spin>
        <div>
          <Paragraph className="mb-0 text-center text-content-tertiary">
            Don’t have an account?{' '}
            <Link onClick={handleSingUpLink} underline>
              Sign Up
            </Link>
          </Paragraph>
        </div>
      </div>
    </section>
  );
};

export default EmailLogin;
