import { gql } from '../../../__generated__';

export const GET_SIGNED_URL = gql(`
 query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {
  getProfileImageUploadSignedUrl(data: $data) {
    key
    signedUrl
  }}`);

export const GET_PLAN_LIST = gql(`
  query getPlanList {
  subscriptionPlanKeyFeatures {
    data {
      id
      keyFeatures
      description
      price
      currency
      type
      title
      label
      recurring
      isSubscribed
      slug
    }
  }
}`);

export const GET_FEATURE_LIST = gql(`
  query featureList {
  subscriptionPlans {
    data {
      id
      label
      type
      title
      features
      isSubscribed
      isActive
      isSubscribed
    }
  }
}`);
