import { Flex, Modal } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import React from 'react';
import CommonButton from '../primitives/CommonButton';

type CommonKeyModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  onReviewSettingsClick: () => void;
};

const PublishAlertModal: React.FC<CommonKeyModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
  onReviewSettingsClick,
  title,
  description,
}) => {
  const ModalTitle = () => (
    <Title level={4} className="font-secondary text-content-primary">
      {title}
    </Title>
  );

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      title={<ModalTitle />}
      open={isVisible}
      onCancel={handleCancel}
      className="publish-alert-modal"
      footer={[
        <CommonButton key="cancel" onClick={onReviewSettingsClick}>
          Review Settings
        </CommonButton>,
        <CommonButton key="confirm" type="primary" onClick={handleConfirm}>
          Publish Now
        </CommonButton>,
      ]}
    >
      <Flex vertical align="flex-start" gap={16}>
        <Paragraph className="text-sm text-content-secondary mb-0">
          {description}
        </Paragraph>
      </Flex>
    </Modal>
  );
};

export default PublishAlertModal;
