import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { Link } from 'react-router-dom';
import { FormThemeMode } from '../../__generated__/graphql';
import { ROUTES } from '../../common/constants';
import { getThemeClassName } from '../../common/utils';
import Logo from '../../components/common/Logo';

export default function MadeWithZinq({ mode }: { mode: FormThemeMode }) {
  return (
    <Flex className="made-with-zinq">
      <Link
        to={ROUTES.MAIN}
        target="_blank"
        className="flex gap-4 items-center"
      >
        <Paragraph
          className={`mb-0 text-meta medium ${getThemeClassName(mode)}`}
        >
          Powered by
        </Paragraph>
        <Logo mode={mode} width={56} />
      </Link>
    </Flex>
  );
}
