import { gql } from '../../../__generated__/gql';

// fetch form editor settings
export const GET_FORM = gql(`
query Form($where: FormUniqueInput!) {
  form(where: $where) {
    data {
      title
      prompt
      id
      previewKey
      status
      isUpdated
      structures {
        overview
        structure
      }
      designs {
        themeId
        themeMode
        themeVariationId
      }
      settings {
        tone
        generativeQuestion
        finalPreview
        notifyOn
        notifyAtDay
        notifyAtSubmission
        closingMessage
      }
    }
  }
}`);

export const GET_FORM_THEMES = gql(`
query FormThemes($data: FormUniqueInput!) {
  formThemes(data: $data) {
    data {
      id
      title
      configs
      isUsed
      type
      themeVariations {
        id
        colorPalette
        isUsed
        title
        type
      }
    }
  }
}`);

export const GET_FORM_DETAILS = gql(`
  query GetForm($where: GetFormWhereInput!) {
  getForm(where: $where) {
    data {
      title
      id
      designs {
        id
        theme {
          configs
        }
        themeVariation {
          colorPalette
          type
        }
        themeMode
      }
      shares {
        id
        formId
        formType
        key
        title
        embedConfigs
        ogTitle
        type
        ogDescription
        ogThumbnail
        ogThumbnailUrl
      }
      structures {
        structure
      }
    }
  }
}`);

export const GET_PAGE_BG_URL = gql(`
  query GetFormStructurePagesImageUrl($data: GetFormStructureImageUrlInput!) {
  getFormStructurePagesImageUrl(data: $data) {
    imageUrl
  }
}`);

export const GET_FORM_SUBMISSION = gql(`
  query FormSubmission($where: SubmissionUniqueInput!) {
  formSubmission(where: $where) {
    data {
      isCompleted
    }
  }
}`);

export const GET_FORM_TRANSCRIPTS = gql(`
  query SubmissionTranscripts($filter: ListSubmissionTranscriptsFilter!, $where: SubmissionUniqueInput!, $sort: [ListSubmissionTranscriptsSort!]) {
  submissionTranscripts(filter: $filter, where: $where, sort: $sort) {
    data {
      configs
      userResponse{
        value
      }
      aiResponse
      options
      type
    }
    count
  }
}`);

export const INITIATE_VOICE_CALL = gql(`
  query InitiateVoiceFormSubmission($data: InitiateVoiceFormSubmissionInput!) {
  initiateVoiceFormSubmission(data: $data) {
    message
  }
}`);

export const GET_FORM_SHARES = gql(`
  query FormShares($where: FormUniqueInput!, $filter: ListFormShareFilter, $sort: [ListFormShareSort!]) {
  formShares(where: $where, filter: $filter, sort: $sort) {
    count
    data {
      formType
      type
      title
      key
      id
      formId
      embedConfigs
    }
  }
}`);

export const GET_SHARE_DETAIL = gql(`
  query FormShare($where: FormShareUniqueInput!) {
  formShare(where: $where) {
    data {
      id
      type
      key
      ogDescription
      ogThumbnail
      ogThumbnailUrl
      ogTitle
      title
      formId
      formType
      embedConfigs
    }
  }
}`);

export const GET_TEMPLATE_DETAIL = gql(`
  query FormTemplateAdmin($where: FormUniqueInput!) {
  formTemplateAdmin(where: $where) {
    id
    categoryId
    category {
      slug
      name
      id
    }
    displayConfig
    slug
    weightage
    description
    tags
  }
}`);

export const GET_FORM_STRUCTURES = gql(`
query FormStructure($where: FormUniqueInput!) {
  form(where: $where) {
    data {
      structures {
        structure
      }
    }
  }
}`);

export const GET_SIGNED_URL_PAGES = gql(`
  query GetFormPageImageUploadSignedUrl($data: GetFormPageImageUploadSignedUrlInput!) {
  getFormPageImageUploadSignedUrl(data: $data) {
    signedUrl
    key
  }
}`);

export const GET_SIGNED_URL_OG_SHARE_IMAGE = gql(`
  query GetFormOgImageUploadSignedUrl($data: GetFormOgImageUploadSignedUrlInput!) {
  getFormOgImageUploadSignedUrl(data: $data) {
    key
    signedUrl
  }
}`);

export const GET_SIGNED_URL_UPLOAD = gql(`
  query GetSubmissionFilesUploadSignedUrl($data: GetFormFilesUploadSignedUrlInput!) {
  getSubmissionFilesUploadSignedUrl(data: $data) {
    signedUrls {
      key
      signedUrl
    }
  }
}`);

// share tab queries
export const GET_RESULT_OVERVIEW = gql(`
  query FormSubmissionOverview($filter: FormSubmissionOverview!, $where: FormUniqueInput!) {
  formSubmissionOverview(filter: $filter, where: $where) {
    data {
      completedSubmissions
      averageSubmissionTime
      incompleteSubmissions
    }
  }
}`);

export const GET_RESULT_BY_QUESTION = gql(`
  query FormSubmissionsByQuestion($filter: ListFormSubmissionsByQuestionFilter!, $where: FormUniqueInput!) {
  formSubmissionsByQuestion(filter: $filter, where: $where) {
    data {
      questionResults {
        data {
          names
          value
          name
          count
        }
        key
        type
      }
      totalSubmissions
      questions
    }
  }
}`);

export const GET_RESULT_BY_PARTICIPANTS = gql(`
 query FormSubmissions($filter: ListFormSubmissionsFilter!, $where: FormUniqueInput!, $sort: [ListFormSubmissionsSort!]) {
  formSubmissions(filter: $filter, where: $where, sort: $sort) {
    count
    data {
      formStructure
      submissions {
        submittedData
        createdAt
        id
        type
        metadata
        voiceConversation {
          aiResponse
          userResponse {
            value
          }
        }
      }
    }
  }
}`);

export const GET_FORM_CATEGORIES_TEMPLATE = gql(`
query FormCategories($filter: ListFormCategoriesFilter, $sort: [ListFormCategoriesSort!]) {
  formCategories(filter: $filter, sort: $sort) {
    data {
      id
      name
      formTemplates {
        slug
        form {
          title
          prompt
          designs {
            themeMode
            themeVariation {
              id
              title
              type
              colorPalette
              isUsed
            }
          }
        }
      }
    }
  }
}`);
