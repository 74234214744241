import { useLazyQuery, useMutation } from '@apollo/client';
import { Plus, SignOut, Trash } from '@phosphor-icons/react';
import { Col, Flex, Form, Input, Row, Spin, Upload, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Paragraph from 'antd/es/typography/Paragraph';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload';
import { isEmpty, omit } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserProfileData } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import {
  ALLOWED_FILE_TYPES,
  DEFAULT_AUTO_SAVE_TIME,
  ROUTES,
} from '../../common/constants';
import {
  beforeUpload,
  commonUploadFile,
  formValidation,
  handleGraphQlError,
  handleGraphQlSuccess,
} from '../../common/utils';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { UPDATE_USER_PROFILE } from '../auth/graphql/mutations';
import { DELETE_USER_ACCOUNT } from './graphql/mutations';
import { GET_SIGNED_URL } from './graphql/queries';
import DeleteAccountModal from './modals/DeleteAccountModal';

export default function ProfileForm() {
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { navigate } = useRouter();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [updateProfileMutate, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    onError: (err) => {
      handleGraphQlError(err.message);
    },
  });

  const [getSignedUrl] = useLazyQuery(GET_SIGNED_URL, {
    onError: (err) => {
      handleGraphQlError(err.message);
    },
  });

  const [profileForm] = useForm();
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const [deleteUserAccount, { loading: deleteLoading }] = useMutation(
    DELETE_USER_ACCOUNT,
    {
      onError: () => {},
    },
  );

  const handleProfileSave = (fields: UserProfileData) => {
    updateProfileMutate({
      variables: {
        data: { ...fields, name: fields.name?.trim() || 'User' },
      },
      onCompleted: (res) => {
        dispatch({
          type: AppActionType.setCurrentUser,
          data: {
            ...currentUser,
            ...fields,
            profileImage:
              res.updateUserProfile?.user?.profileImage ??
              currentUser?.profileImage,
          },
        });
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      const formValues = {
        ...currentUser,
        countryCode: currentUser.countryCode?.split('+')?.[1],
      };
      profileForm.setFieldsValue(formValues);
      if (currentUser.profileImage) {
        setFileList([
          {
            url: currentUser.profileImage,
            uid: '-1',
            name: '',
            status: 'done',
          },
        ]);
      }
    }
  }, [currentUser]);

  const handleChange = async (info: UploadChangeParam) => {
    if (info.fileList?.length > 0) {
      if (beforeUpload(info.file)) {
        setFileList(info.fileList);
        try {
          const { data } = await getSignedUrl({
            variables: {
              data: {
                fileName: info.file.name,
              },
            },
          });

          const signedUrl = data?.getProfileImageUploadSignedUrl?.signedUrl;
          const key = data?.getProfileImageUploadSignedUrl?.key;

          if (signedUrl) {
            const uploadSuccess = await commonUploadFile(
              signedUrl,
              info.file as RcFile,
            );
            if (uploadSuccess) {
              handleProfileSave({ profileImage: key });
            } else {
              message.error('Failed to upload image');
              return;
            }
          }
        } catch (error) {
          handleGraphQlError('Failed to get signed URL');
          return;
        }
      }
    } else {
      setFileList([]);
    }
  };

  const handleOnOk = () => {
    deleteUserAccount({
      onCompleted: (data) => {
        handleGraphQlSuccess(data.deleteAccount?.message);
        dispatch({ type: AppActionType.logout });
        navigate(ROUTES.LOGIN);
      },
    });
  };

  const handleOnCancel = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    setFileList([]);
    handleProfileSave({ profileImage: null });
  };

  const handleAutoSave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      const allFields = omit(profileForm.getFieldsValue(), ['email']);
      handleProfileSave(allFields);
    }, DEFAULT_AUTO_SAVE_TIME);
  };

  return (
    <div className="profile-form">
      <Spin spinning={loading}>
        <Form
          form={profileForm}
          name="contact-form"
          onFieldsChange={handleAutoSave}
          layout="vertical"
          initialValues={{ countryCode: '+91' }}
        >
          <Form.Item label="Profile Image">
            <Upload
              name="file"
              listType="picture-card"
              showUploadList
              accept={ALLOWED_FILE_TYPES.profileImage}
              fileList={fileList}
              beforeUpload={() => false}
              multiple={false}
              onChange={handleChange}
              onRemove={handleRemove}
            >
              {fileList.length >= 1 ? null : <Plus />}
            </Upload>
          </Form.Item>
          <Form.Item name="name" label="Name">
            <Input placeholder="Enter your name" />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[formValidation.email]}>
            <Input placeholder="Enter your email" disabled />
          </Form.Item>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Link to={ROUTES.LOGOUT}>
                <CommonButton type="text" icon={<SignOut />}>
                  Sign Out
                </CommonButton>
              </Link>
            </Col>
            <Col span={24}>
              <Flex
                align="center"
                gap={8}
                className="w-max-content cursor-pointer"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <Trash color="var(--danger-on-surface)" />
                <Paragraph className="mb-0 text-danger-on-surface">
                  Delete Account
                </Paragraph>
              </Flex>
            </Col>
          </Row>
        </Form>
      </Spin>
      <DeleteAccountModal
        onOk={handleOnOk}
        open={open}
        onCancel={handleOnCancel}
        okLoading={deleteLoading}
      />
    </div>
  );
}
