import { PlusCircle } from '@phosphor-icons/react';
import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { EditCardSvg, StarCardSvg } from '../../../assets/icons';
import { ROUTES } from '../../../common/constants';
import FormTypeModal from '../../../components/common/FormTypeModal';
import useRouter from '../../../hooks/useRouter';

export default function CreateCard() {
  const { navigate } = useRouter();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        handleClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleModalCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleBlankFormClick = () => {
    setIsModalOpen(false);
    navigate(ROUTES.FORM_TEMPLATES);
  };

  const handleTemplateFormClick = () => {
    setIsModalOpen(false);
    navigate(ROUTES.FORM_TEMPLATES);
  };

  return (
    <>
      <div className="create-card" onClick={handleClick}>
        <Flex vertical gap={12} align="center">
          <PlusCircle color="var(--content-primary)" size={40} weight="fill" />
          <Flex gap={4} vertical>
            <Title
              level={4}
              className="mb-0 text-center text-content-primary font-secondary"
            >
              New Form
            </Title>
            <Paragraph className="mb-0 text-center text-content-secondary">
              (Ctrl/⌘ + K)
            </Paragraph>
          </Flex>
        </Flex>
      </div>
      <FormTypeModal
        open={isModalOpen}
        onCancel={() => {
          handleModalCancel();
        }}
        title="New Form"
        description="Select an option to continue,"
        cards={[
          {
            title: 'AI Form',
            description:
              'Let AI enhance your form experience! Get smarter, conversational forms that guide users seamlessly.',
            buttonText: 'Create',
            onClick: handleBlankFormClick,
            isPremium: true,
            cardIcon: <StarCardSvg />,
          },
          {
            title: 'Classic Form',
            description:
              'Keep it simple. Build traditional forms without AI, designed for straightforward filling.',
            buttonText: 'Create',
            onClick: handleTemplateFormClick,
            cardIcon: <EditCardSvg />,
          },
        ]}
      />
    </>
  );
}
